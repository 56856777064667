import { DATA_TYPE_INFOS, DATA_TYPE_PMRS } from './dataConfig'; // import {  } from 'src/pages/pagesKeys';

export function isLogoFullWidth(pageKey) {
  switch (pageKey) {
    default:
      return true;
  }
}
export var CATEGORIES_CLASSIFICATIONS = {
  CATEGORY_SYSTEM: 'CATSYS',
  TOP_CATS: 'TOPCATS'
};
export var EVENT_PAGE_SPEAKERS_AS_ACCORDION = false;
export var SINGLE_ITEM_DATATYPES = [DATA_TYPE_INFOS, DATA_TYPE_PMRS];
export var DEFAULT_IMAGE = {}; // CATEGORY_SYSTEM || TOP_CATS

export var CATEGORIES_CLASSIFICATION = CATEGORIES_CLASSIFICATIONS ? CATEGORIES_CLASSIFICATIONS.TOP_CATS : null;