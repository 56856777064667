import _defineProperty from "/Users/mohamedbenkhedher/Documents/react/mspot-printemps/app-react/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { DATA_TYPE_AIRCRAFT_CATEGORIES, DATA_TYPE_AIRCRAFTS, DATA_TYPE_ANIMATIONS, DATA_TYPE_BRAND_CATEGORIES, DATA_TYPE_BRANDS, DATA_TYPE_EVENT_CATEGORIES, DATA_TYPE_EVENTS, DATA_TYPE_EXHIBITOR_CATEGORIES, DATA_TYPE_EXHIBITORS, DATA_TYPE_FLIGHTS_SCHEDULE, DATA_TYPE_NEWPRODUCT_CATEGORIES, DATA_TYPE_NEWPRODUCTS, DATA_TYPE_RESTAURANT_CATEGORIES, DATA_TYPE_RESTAURANTS, DATA_TYPE_MONUMENTS, DATA_TYPE_SERVICE_CATEGORIES, DATA_TYPE_SERVICES, DATA_TYPE_DOCUNITS, DATA_TYPE_DOCUNIT_CATEGORIES, DATA_TYPE_SPEAKERS, DATA_TYPE_PLACES, DATA_TYPE_PARTICIPANTS, DATA_TYPE_FAVORITE_POSITIONS, CATEGORIES_MAPPING, getSpeakerTitle, getSpeakerSubtitle, DATA_TYPE_HAPPENINGS, DATA_TYPE_BUILDINGS, DATA_TYPE_FLOORS } from './dataConfig';
import { EVENT_PAGE_KEY, LIST_PAGE_KEY, RESTAURANT_PAGE_KEY } from 'src/pages/pagesKeys';
import * as Query from 'src/core/query/Query';
import { DATA_TYPE_TO_PAGE_KEY } from 'src/pages/dataToPageMapping';
import { formatDate, formatTime } from 'src/core/Lang';
import TwoColumnsModeManager from 'src/core/navigation/TwoColumnsModeManager';
import { openUrl } from 'src/core/util/JsTools';
import { sendPageView } from 'src/core/analytics/GoogleAnalyticsHelper';
import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';
import { isAndroid, isIOS } from 'src/core/util/browser';
import { capitalize } from 'src/core/util/StringUtil';
var LOG_PREF = '[listConfig] ';
export var ROW_BACKGROUND_COLOR_ON_CLICK = '#efefef';
/**
 * Display alphabetical index if row count if above
 * @type {Number}
 */

export var LIST_SIDEINDEX_THRESHOLD = 50;
/**
 * Specific threshold for lists of groups (e.g events)
 * @type {Number}
 */

export var LIST_OF_GROUPS_SIDEINDEX_THRESHOLD = 20;
/**
 * List displays only the items beginning with the current alphabetical index
 * @type {Number}
 */

export var ALPHABETICAL_LIST_BY_INDEX_IF_ABOVE_ANDROID = 200;
export var ALPHABETICAL_LIST_BY_INDEX_IF_ABOVE_IOS = 2000;
export var ALPHABETICAL_LIST_BY_INDEX_IF_ABOVE_WEB = 1000;
export var getAlphabeticalListByIndexThreshold = function getAlphabeticalListByIndexThreshold() {
  if (isAndroid()) {
    return ALPHABETICAL_LIST_BY_INDEX_IF_ABOVE_ANDROID;
  }

  if (isIOS()) {
    return ALPHABETICAL_LIST_BY_INDEX_IF_ABOVE_IOS;
  }

  return ALPHABETICAL_LIST_BY_INDEX_IF_ABOVE_WEB;
};
/**
 * Hide items counter in category list
 * @type {Boolean}
 */

export var HIDE_CATEGORY_ITEMS_COUNTER = true; // Typical use case: search results

export var HIGHLIGHTED_PROPS = ['text', 'textMinor'];
export var DISABLE_FAVORITE_ICON = false;
/**
 * TypeBar is automatically closed when items count is higher than this
 * @see DataListDialog
 * @type {Number}
 */

export var DIALOG_TYPEBAR_CLOSED_IF_COUNT_ABOVE = 5;
/**
 * Indicate if user is redirected to map (show POI) when selecting an item
 * @param  {string} dataType
 * @return {boolean}
 */

export function shouldRedirectToExternalLink(dataType) {
  switch (dataType) {
    /*    case DATA_TYPE_NEWPRODUCTS:
      // Show on external link
      return true; */
    default:
      return false;
  }
}
export var DISABLE_FAVORITE_BY_DATATYPE = [];
/**
 * Ability to modify the side of the alphabetical index
 * @return {string}
 */

export var getSideIndexPosition = function getSideIndexPosition() {
  return (// On desktop with app displayed in two columns, put the index on the left side
    // to prevent list scrollTop to be modified when cursor moves from left column to right column.
    TwoColumnsModeManager.isEnabled() && global.isCordovaContext === false ? 'left' : 'right'
  );
};

function hasPlaces(row) {
  return Array.isArray(row.places) && row.places.length > 0;
}

function serviceHasContent(service) {
  return !!(service.description || service.phone || service.website || service.email);
}
/**
 * Define here the `props` (~ attributes) an element of a list will receive.
 * Every helper function receives as parameter the full object
 *----------------------------------------------------------------------
 * See app-react/src/components/list/ListElement.js for available props
 *----------------------------------------------------------------------
 */


export function elementPropsGetters(dataType, pageKey) {
  var _ref, _ref2;

  switch (dataType) {
    // NB: generic props are set by List component:
    //       - 'data-id' (data item id)
    //       - 'data-type'
    case DATA_TYPE_PLACES:
      return {
        key: function key(place) {
          return place.id + '-' + place.memberType + '-' + place.memberId;
        },
        text: function text(place) {
          return capitalize(String(place.memberTitle));
        },
        textMinor: function textMinor(place) {
          var _place$lump, _place$lump2, _place$lump2$DATA_TYP, _place$lump3, _place$lump4, _place$lump4$DATA_TYP;

          var floor = ((_place$lump = place.lump) === null || _place$lump === void 0 ? void 0 : _place$lump[DATA_TYPE_FLOORS]) && Query.get((_place$lump2 = place.lump) === null || _place$lump2 === void 0 ? void 0 : (_place$lump2$DATA_TYP = _place$lump2[DATA_TYPE_FLOORS]) === null || _place$lump2$DATA_TYP === void 0 ? void 0 : _place$lump2$DATA_TYP[0], DATA_TYPE_FLOORS);
          var bat = ((_place$lump3 = place.lump) === null || _place$lump3 === void 0 ? void 0 : _place$lump3[DATA_TYPE_BUILDINGS]) && Query.get((_place$lump4 = place.lump) === null || _place$lump4 === void 0 ? void 0 : (_place$lump4$DATA_TYP = _place$lump4[DATA_TYPE_BUILDINGS]) === null || _place$lump4$DATA_TYP === void 0 ? void 0 : _place$lump4$DATA_TYP[0], DATA_TYPE_BUILDINGS);
          var floorBatDisplay = (bat === null || bat === void 0 ? void 0 : bat.title) + ' . ' + (floor === null || floor === void 0 ? void 0 : floor.prefix);
          var subTitle = place === null || place === void 0 ? void 0 : place.label;

          if (floor && bat) {
            var subTitleSplit = subTitle === null || subTitle === void 0 ? void 0 : subTitle.split('<br>');
            var textToDisplay = subTitle ? (subTitleSplit === null || subTitleSplit === void 0 ? void 0 : subTitleSplit.length) > 0 ? (subTitleSplit === null || subTitleSplit === void 0 ? void 0 : subTitleSplit[0]) !== '' ? (subTitleSplit === null || subTitleSplit === void 0 ? void 0 : subTitleSplit[0]) + '<br>' + floorBatDisplay : floorBatDisplay : subTitleSplit + '<br>' + floorBatDisplay : floorBatDisplay;
            return capitalize(textToDisplay);
          }

          return capitalize(place === null || place === void 0 ? void 0 : place.label);
        },
        'data-member-type': function dataMemberType(place) {
          return String(place.memberType);
        },
        'data-member-id': function dataMemberId(place) {
          return String(place.memberId);
        },
        'data-member-original-id': function dataMemberOriginalId(place) {
          return String(place.memberOriginalId);
        }
      };

    case DATA_TYPE_ANIMATIONS:
      return {
        text: function text(anim) {
          return String(anim.title);
        },
        'data-original-id': function dataOriginalId(anim) {
          return String(anim.original_id);
        },
        'data-contextual-place-id': function dataContextualPlaceId(anim) {
          return String(anim.contextualPlaceId);
        }
      };

    case DATA_TYPE_HAPPENINGS:
      return {
        text: function text(happ) {
          return capitalize(String(happ.title));
        },
        originalId: function originalId(happ) {
          return String(happ.original_id);
        },
        id: function id(happ) {
          return happ.id;
        },
        places: function places(happ) {
          return happ.places;
        },
        description: function description(happ) {
          return String(happ.description);
        },
        logo_file_name: function logo_file_name(happ) {
          return String(happ.logo_file_name);
        },
        video: function video(happ) {
          return happ.lump && String(happ.lump.video);
        },
        lump: function lump(happ) {
          return happ.lump;
        }
      };

    case DATA_TYPE_BRANDS:
      return {
        text: function text(brand) {
          return capitalize(String(brand.title));
        },
        textMinor: function textMinor(brand) {
          var _brand$lump, _brand$lump2, _brand$lump2$DATA_TYP, _brand$lump3, _brand$lump4, _brand$lump4$DATA_TYP, _brand$lump5, _brand$lump6;

          var floor = ((_brand$lump = brand.lump) === null || _brand$lump === void 0 ? void 0 : _brand$lump[DATA_TYPE_FLOORS]) && Query.get((_brand$lump2 = brand.lump) === null || _brand$lump2 === void 0 ? void 0 : (_brand$lump2$DATA_TYP = _brand$lump2[DATA_TYPE_FLOORS]) === null || _brand$lump2$DATA_TYP === void 0 ? void 0 : _brand$lump2$DATA_TYP[0], DATA_TYPE_FLOORS);
          var bat = ((_brand$lump3 = brand.lump) === null || _brand$lump3 === void 0 ? void 0 : _brand$lump3[DATA_TYPE_BUILDINGS]) && Query.get((_brand$lump4 = brand.lump) === null || _brand$lump4 === void 0 ? void 0 : (_brand$lump4$DATA_TYP = _brand$lump4[DATA_TYPE_BUILDINGS]) === null || _brand$lump4$DATA_TYP === void 0 ? void 0 : _brand$lump4$DATA_TYP[0], DATA_TYPE_BUILDINGS);
          var floorBatDisplay = (bat === null || bat === void 0 ? void 0 : bat.title) + ' . ' + (floor === null || floor === void 0 ? void 0 : floor.prefix);
          var subTitle = brand === null || brand === void 0 ? void 0 : (_brand$lump5 = brand.lump) === null || _brand$lump5 === void 0 ? void 0 : _brand$lump5.subtitle;

          if (floor && bat) {
            var subTitleSplit = subTitle === null || subTitle === void 0 ? void 0 : subTitle.split('<br>');
            var textToDisplay = subTitle ? (subTitleSplit === null || subTitleSplit === void 0 ? void 0 : subTitleSplit.length) > 0 ? (subTitleSplit === null || subTitleSplit === void 0 ? void 0 : subTitleSplit[0]) !== '' ? (subTitleSplit === null || subTitleSplit === void 0 ? void 0 : subTitleSplit[0]) + '<br>' + floorBatDisplay : floorBatDisplay : subTitleSplit + '<br>' + floorBatDisplay : floorBatDisplay;
            return capitalize(textToDisplay);
          }

          return capitalize(brand === null || brand === void 0 ? void 0 : (_brand$lump6 = brand.lump) === null || _brand$lump6 === void 0 ? void 0 : _brand$lump6.subtitle);
        },
        'data-original-id': function dataOriginalId(brand) {
          return String(brand.original_id);
        }
      };

    case DATA_TYPE_SERVICES:
      return {
        text: function text(service) {
          return capitalize(String(service.title));
        },
        textMinor: function textMinor(service) {
          return capitalize(service.lump.subtitle);
        },
        // image: service => service.logo_file_name,
        'data-link': function dataLink(service) {
          return service.lump && service.lump.link || null;
        },
        'data-original-id': function dataOriginalId(service) {
          return String(service.original_id);
        },
        'data-contextual-place-id': function dataContextualPlaceId(service) {
          return service.contextualPlaceId;
        },
        'data-has-places': function dataHasPlaces(service) {
          return hasPlaces(service);
        },
        'data-no-content': function dataNoContent(service) {
          return serviceHasContent(service) !== true;
        },
        isClickable: function isClickable(service) {
          return hasPlaces(service) || serviceHasContent(service);
        } // default: true

      };

    case DATA_TYPE_EXHIBITORS:
      return {
        'data-original-id': function dataOriginalId(exh) {
          return String(exh.original_id);
        },
        'data-contextual-place-id': function dataContextualPlaceId(exh) {
          return exh.contextualPlaceId;
        },
        text: function text(exh) {
          return capitalize(String(exh.title));
        },
        //textMinor: exh => exh.placeLabel,
        textStyle: function textStyle(exh) {
          return exh.lump && exh.lump.color ? {
            color: exh.lump.color
          } : null;
        },
        className: function className(exh) {
          var classes = [];

          if (exh.lump) {
            if (exh.lump.bold === '1') {
              classes.push('emphasised');
            }
          } // to be completed


          return classes.join(' ');
        }
      };

    case DATA_TYPE_AIRCRAFTS:
      return {
        text: function text(aircraft) {
          return String(aircraft.title);
        },
        'data-original-id': function dataOriginalId(aircraft) {
          return String(aircraft.original_id);
        },
        'data-contextual-place-id': function dataContextualPlaceId(aircraft) {
          return String(aircraft.contextualPlaceId);
        }
      };

    case DATA_TYPE_RESTAURANTS:
      return {
        text: function text(restau) {
          return capitalize(String(restau.title));
        },
        textMinor: function textMinor(restau) {
          return capitalize(restau.lump.subtitle);
        },
        'data-original-id': function dataOriginalId(restau) {
          return String(restau.original_id);
        }
      };

    case DATA_TYPE_MONUMENTS:
      return {
        text: function text(monu) {
          return capitalize(String(monu.title));
        },
        textMinor: function textMinor(monu) {
          return capitalize(monu.lump.subtitle);
        },
        'data-original-id': function dataOriginalId(monu) {
          return String(monu.original_id);
        }
      };

    case DATA_TYPE_SPEAKERS:
      return _ref = {
        text: function text(speaker) {
          return capitalize(getSpeakerTitle(speaker));
        },
        textMinor: function textMinor(speaker) {
          return capitalize(getSpeakerSubtitle(speaker));
        }
      }, _defineProperty(_ref, 'data-' + DATA_TYPE_EVENTS, function (speaker) {
        return speaker.lump[DATA_TYPE_EVENTS];
      }), _defineProperty(_ref, "image", function image(speaker) {
        return speaker.photo;
      }), _defineProperty(_ref, "isClickable", function isClickable(speaker) {
        return pageKey !== EVENT_PAGE_KEY && Array.isArray(speaker.lump[DATA_TYPE_EVENTS]) && speaker.lump[DATA_TYPE_EVENTS].length > 0;
      }), _ref;

    case DATA_TYPE_EVENTS:
      return {
        'data-original-id': function dataOriginalId(event) {
          return String(event.original_id);
        },
        'data-contextual-place-id': function dataContextualPlaceId(event) {
          return String(event.contextualPlaceId);
        },
        event: function event(_event) {
          return {
            start_date: _event.start_date,
            start_time: _event.start_time,
            end_date: _event.end_date,
            end_time: _event.end_time
          };
        },
        text: function text(event) {
          return capitalize(String(event.title));
        },
        textMinor: function textMinor(event) {
          return event.place !== null && typeof event.place === 'object' ? capitalize(String(event.place.label)) : null;
        }
      };

    case DATA_TYPE_FLIGHTS_SCHEDULE:
      return {
        'data-id': function dataId(flight) {
          return flight.id;
        },
        text: function text(flight) {
          return flight.aircraft && flight.aircraft.title || 'Unknown aircraft';
        },
        event: function event(flight) {
          var start = flight.time_start.split(':');
          return {
            start_date: Number(flight.date),
            start_time: start.length > 2 ? start.slice(0, 2).join(':') : start.join(':'),
            end_date: Number(flight.date),
            end_time: ''
          };
        },
        textMinor: function textMinor(flight) {
          return flight.time_end;
        },
        // aircraft desc is very long
        isClickable: function isClickable(flight) {
          return flight.hasAircraft;
        }
      };

    case DATA_TYPE_NEWPRODUCTS:
      return {
        text: function text(np) {
          return String(np.title);
        },
        textMinor: function textMinor(np) {
          return np.exhibitor ? String(np.exhibitor.title) : null;
        },
        image: function image(np) {
          return np.photo_file_name;
        }
      };

    case DATA_TYPE_FAVORITE_POSITIONS:
      return _ref2 = {}, _defineProperty(_ref2, 'data-id', function dataId(fav) {
        return fav.id;
      }), _defineProperty(_ref2, "text", function text(fav) {
        return capitalize(fav.title);
      }), _ref2;

    case DATA_TYPE_DOCUNITS:
      return {
        data_id: function data_id(docunit) {
          return docunit.id;
        },
        'data-original-id': function dataOriginalId(docunit) {
          return docunit.original_id;
        },
        text: function text(docunit) {
          return docunit.title;
        },
        image: function image(docunit) {
          return docunit.image ? docunit.image : null;
        }
      };

    case DATA_TYPE_AIRCRAFT_CATEGORIES:
    case DATA_TYPE_BRAND_CATEGORIES:
    case DATA_TYPE_EVENT_CATEGORIES:
    case DATA_TYPE_EXHIBITOR_CATEGORIES:
    case DATA_TYPE_RESTAURANT_CATEGORIES:
    case DATA_TYPE_SERVICE_CATEGORIES:
    case DATA_TYPE_NEWPRODUCT_CATEGORIES:
    case DATA_TYPE_DOCUNIT_CATEGORIES:
      return {
        counter: function counter(cat) {
          return cat.counter;
        },
        text: function text(cat) {
          return capitalize(String(cat.title));
        },
        image: function image(cat) {
          return cat.lump && cat.lump.logo;
        },
        textMinor: function textMinor(cat) {
          return capitalize(cat.lump.subtitle) || null;
        }
      };

    default:
      console.error(LOG_PREF + 'No helpers specified for type `' + dataType + '`');
  }
}
/**
 * Indicate if user is redirected to map (show POI) when selecting an item
 * @param  {string} dataType
 * @return {boolean}
 */

export function shouldRedirectToMap(dataType, id) {
  var noRedirectKeys = ['logo_file_name', 'description', 'video', 'phone', 'website', 'email'];
  var item = Query.get(id, dataType);

  for (var key in item) {
    if (noRedirectKeys.includes(key) && (item[key] || item[key] !== '')) {
      return false;
    }
  }

  return true; // switch (dataType) {
  //   // case DATA_TYPE_BRANDS
  //   //   // Show POI on map
  //   //   return true;
  //   default:
  //     return false;
  // }
}
/**
 * Define here what should happen when a note list entry is clicked
 * @param {DOM element} liEl
 * @param {object} actions: redux actions (connected to page instance)
 * @param {object} labels
 * @param {string} pageKey (optional related page identifier)
 */

export function onClickOnNote(liEl, actions, labels, pageKey) {
  actions.showNoteModal(liEl.title, liEl.dataset.id, liEl.dataset.type, liEl);
}
/**
 * Define here what should happen when a list entry (<ListElements />) is clicked
 * @param {DOM element} liEl
 * @param {object} actions: redux actions (connected to page instance)
 * @param {object} labels
 * @param {string} pageKey (optional related page identifier)
 */

export function onClickOnLi(liEl, actions, labels, pageKey) {
  var _liEl$dataset = liEl.dataset,
      id = _liEl$dataset.id,
      type = _liEl$dataset.type,
      originalId = _liEl$dataset.originalId; // Auto parse id

  if (/^\d+$/.test(id)) {
    id = parseInt(id, 10);
  }

  switch (type) {
    case DATA_TYPE_AIRCRAFT_CATEGORIES:
    case DATA_TYPE_BRAND_CATEGORIES:
    case DATA_TYPE_EVENT_CATEGORIES:
    case DATA_TYPE_EXHIBITOR_CATEGORIES:
    case DATA_TYPE_RESTAURANT_CATEGORIES:
    case DATA_TYPE_SERVICE_CATEGORIES:
    case DATA_TYPE_NEWPRODUCT_CATEGORIES:
    case DATA_TYPE_DOCUNIT_CATEGORIES:
      actions.categoryClicked(id, type);
      break;

    case DATA_TYPE_AIRCRAFTS:
    case DATA_TYPE_ANIMATIONS:
    case DATA_TYPE_BRANDS:
    case DATA_TYPE_EXHIBITORS:
    case DATA_TYPE_EVENTS:
    case DATA_TYPE_NEWPRODUCTS:
    case DATA_TYPE_RESTAURANTS:
    case DATA_TYPE_MONUMENTS:
    case DATA_TYPE_HAPPENINGS:
    case DATA_TYPE_DOCUNITS:
    case DATA_TYPE_PARTICIPANTS:
      actions.genericItemNavigation(type, id, originalId);
      break;

    case DATA_TYPE_SERVICES:
      if (liEl.dataset.link) {
        openUrl(getUrl(liEl.dataset.link, global.isCordovaContext ? false : true, // use http
        true // absolute
        ));
        sendPageView(liEl.dataset.link);
      } else if (liEl.dataset.noContent === 'true' && liEl.dataset.hasPlaces === 'true') {
        actions.showOnePoiOnMobigeo({
          type: type,
          id: id,
          originalId: originalId
        });
      } else {
        actions.genericItemNavigation(type, id, originalId);
      }

      break;

    case DATA_TYPE_SPEAKERS:
      if (pageKey === EVENT_PAGE_KEY) {
        // Already on event page
        return;
      }

      var events = liEl.dataset[DATA_TYPE_EVENTS];

      if (events) {
        events = events.split(',');
      } else {
        events = [];
      }

      switch (events.length) {
        case 0:
          console.warn(LOG_PREF + "Speaker id:".concat(id, " has no event")); // noop

          break;

        case 1:
          // Display event page
          actions.navigate(EVENT_PAGE_KEY, {
            id: parseInt(events[0], 10)
          });
          break;

        default:
          // More than 1 event = Display list of events
          actions.navigate(LIST_PAGE_KEY, {
            inputs: [{
              parentId: id,
              parentType: DATA_TYPE_SPEAKERS,
              dataType: DATA_TYPE_EVENTS
            }]
          });
      }

      break;

    case DATA_TYPE_PLACES:
      var searchType = liEl.parentNode.dataset.searchType,
          textMinor = liEl.querySelector('.text-minor');
      actions.searchedPlaceSelected(searchType, {
        placeId: id,
        type: liEl.dataset.memberType,
        id: liEl.dataset.memberId,
        originalId: liEl.dataset.memberOriginalId,
        text: capitalize(liEl.getAttribute('title')),
        textMinor: textMinor ? capitalize(textMinor.textContent) : null
      });
      break;

    case DATA_TYPE_FAVORITE_POSITIONS:
      actions.showOnePoiOnMobigeo({
        type: type,
        id: id,
        originalId: null
      });
      break;

    case DATA_TYPE_FLIGHTS_SCHEDULE:
      actions.flightScheduleClicked(id);
      break;

    default:
      console.error(LOG_PREF + 'Nothing defined for click on type `' + type + '`');
  }
}