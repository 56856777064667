// const { PROFILE_EXPOSANT, PROFILE_VISITEUR, PROFILE_PRESSE } = require('./profiles');
var _require = require('../../src/pages/pagesKeys'),
    AGENDA_PAGE = _require.AGENDA_PAGE,
    FAVORITES_PAGE_KEY = _require.FAVORITES_PAGE_KEY,
    HOME_PAGE_KEY = _require.HOME_PAGE_KEY,
    HOURS_PAGE_KEY = _require.HOURS_PAGE_KEY,
    LIST_PAGE_KEY = _require.LIST_PAGE_KEY;
/**
 * Toolbar configuration per profile
 */


export default function (profile, pageKey) {
  // Home does not have AppToolbar component
  if (pageKey === HOME_PAGE_KEY) {
    return {};
  }

  switch (profile) {
    default:
      return {
        homeButtonInToolbar: false,
        favButtonInToolbar: false,
        searchButtonInToolbar: pageKey !== HOURS_PAGE_KEY,
        menuButtonInToolbar: true,
        rootCategoryButtonInToolbar: false
      };
  }
}
;