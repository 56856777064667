module.exports = {
  name: "printemps",
  client: "Printemps Haussmann",
  codifName: "M001",
  description: "Projet MobiGeo pour l'application Printemps Haussmann",
  validLangs: ["en", "fr", "zh"],
  native: {
    author: "Mobile-Spot",
    backgroundColor: "0xffffff",
    iosAppStoreUrl: "https://itunes.apple.com/app/printemps-haussmann/id853771459",
    iosBuildTarget: "11.0",
    playStoreUrl: "https://play.google.com/store/apps/details?id=com.printemps.haussmann",
    androidFadeSplashScreenDuration: 750,
    androidTargetSdkVersion: 30,
    app: "app-react"
  },
  undeliveredFolders: ["source", "exports"],
  cordova: true,
  crypto: false,
  customFileUrlScheme: "msfile",
  cordova_plugins: {
    mobigeo_plugin_dependencies: [{
      id: "cordova-plugin-bluetooth-status"
    }],
    app_dependencies: [{
      id: "https://github.com/etienneMobileSpot/cordova-plugin-geolocation.git#permissionsTests"
    }, {
      id: "../src-app-plugins/cordova-plugin-mobilespot-app",
      variable: "HOST",
      varValue: "web.url"
    }, {
      id: "../src-app-plugins/cordova-plugin-mobilespot-badge-scan"
    }, {
      id: "cordova-plugin-file"
    }, {
      id: "cordova-plugin-file-opener2",
      variable: "ANDROID_SUPPORT_V4_VERSION",
      rawVarValue: "27.+"
    }]
  }
};