import React from 'react';
import PropTypes from 'prop-types';
import Switch from 'react-switch';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ListItem from '@material-ui/core/ListItem';

import { PMR_BUTTON_PROPS } from 'data/config/menuConfig';
import { StoredManualPMRStatus } from 'src/pages/pmr/PmrHelper';
import { MOBIGEO_PAGE_KEY } from 'src/pages/pagesKeys';

import { PMR_CAPABILITY } from 'data/config/mobigeoConfig';

import * as actions from 'src/store/actions';

function noop() {}

class TogglePMRItem extends React.PureComponent {
  togglePMR = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (this.props.isPMREnabled === true) {
      this.props.actions.togglePMRStatus(false);
      StoredManualPMRStatus.setDisabled();
    } else {
      this.props.actions.togglePMRStatus(true);
      StoredManualPMRStatus.setEnabled();
    }
  };

  UNSAFE_componentWillMount() {
    if (StoredManualPMRStatus.hasBeenEnabled()) {
      this.props.actions.togglePMRStatus(true);
    } else {
      this.props.actions.togglePMRStatus(false);
    }
  }

  render() {
    // CONTAINER
    let entry = this.props.entry;
    let hasContainerStyle =
        entry.style && typeof entry.style.container === 'object' && entry.style.container !== null,
      attributes = {},
      classes = [];

    // css
    if (hasContainerStyle && entry.style.container.style) {
      attributes.style = entry.style.container.style;
    }
    //if (isDisabled === true) {
    //    classes.push('menu-item-disabled');
    //}
    if (hasContainerStyle && entry.style.container.className) {
      classes.push(entry.style.container.className);
    }
    attributes.className = classes.join(' ');

    // ICON
    let hasIconStyle =
        entry.style && typeof entry.style.icon === 'object' && entry.style.icon !== null,
      iconClasses = ['menu-icon'];

    if (hasIconStyle && entry.style.icon.className) {
      iconClasses.push(entry.style.icon.className);
    }
    if (!PMR_CAPABILITY) {
      return null;
    }
    return (
      <ListItem {...attributes} onClick={this.togglePMR}>
        <div className="menu-item content-font">
          <span
            className={iconClasses.join(' ')}
            style={hasIconStyle && entry.style.icon.style ? entry.style.icon.style : null}
          />
         

          {!this.props.hideLabel&&<div className="menu-text">{this.props.label ? this.props.label : entry.label}</div>}
        </div>

        <div className="menu-item content-font menu-item-switch">
          {/* see:
                         https://github.com/markusenglund/react-switch
                         https://react-switch.netlify.com/
                     */}
          <Switch
            onChange={noop}
            checked={this.props.isPMREnabled}
            className="react-switch"
            {...PMR_BUTTON_PROPS}
          />
        </div>
      </ListItem>
    );
  }
}

TogglePMRItem.propTypes = {
  actions: PropTypes.object.isRequired,
  isPMREnabled: PropTypes.bool,
};
const mapStateToProps = (state, ownProps) => state[MOBIGEO_PAGE_KEY];
const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(mapStateToProps, mapDispatchToProps)(TogglePMRItem);
