module.exports = require('deepmerge')(require('./config.default.js'), {
  name: "printemps-beta",
  native: {
    appleTeamId: '2BAP3P29V2',
    name: {
      default: 'Haussmann Staging'
    },
    id: 'com.printemps.haussmann.staging',
    appleAppId: '1012404510',
    androidSigning: {
      keyAlias: "Haussmann",
      keyStorePassword: "wui1ieph7EiC8OL3"
    }
  },
  version: {
    mainVersion: "3.9.2",
    buildVersion: {
      iOS: "37",
      android: "36",
      web: "37"
    }
  },
  relatedRepos: [{
    src: 'git@bitbucket.org:mobilespotdev/cordova.git',
    postCommands: ['cd cordova && npm install'],
    branch: 'inte/printemps/main'
  }, {
    src: 'git@bitbucket.org:mobilespotdev/mobigeo.git',
    postCommands: ['cd mobigeo && npm install'],
    branch: 'inte/printemps/main'
  }, {
    src: 'git@bitbucket.org:mobilespotdev/mobigeo-customs-printemps.git',
    dest: 'mobigeo/mobigeo-customs',
    branch: 'inte/printemps/main'
  }, {
    src: 'git@bitbucket.org:mobilespotdev/app-react.git',
    branch: 'inte/printemps/main'
  }, {
    src: 'git@bitbucket.org:mobilespotdev/data-printemps.git',
    dest: 'app-react/data',
    branch: 'inte/printemps/main',
    postCommands: ['cd app-react && yarn', 'cd app-react && npm run set-env staging', 'cd app-react && npm run download-assets', 'cd app-react && npm run download-data-files', 'cd app-react && npm run update-mobigeo']
  }],
  web: {
    url: 'ph-staging.mobile-spot.com'
  }
});