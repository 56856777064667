import React from 'react';
import PropTypes from 'prop-types';

import YouTube from 'react-youtube';
import './YoutubePlayer.scss';

const checkEmbedId = (string) => {
  if (string.indexOf('watch?v=') > -1) {
    const id = string.split('watch?v=')[1].split('&')[0];
    // return `https://www.youtube.com/embed/${id}?rel=0&modestbranding=1&autoplay=1`;
    return id
  } else if (string.indexOf('embed') > -1) {
    return string.split('embed/')[1].split('?')[0];
  }
  return string
};

const YoutubePlayer = ({ embedId, height, width, autoplay }) => {
  const id = checkEmbedId(embedId);
  // const iframeCustom = () => (
  //   <div className="video_container">
  //     <iframe
  //       width={width}
  //       height={height}
  //       src={url}
  //       frameBorder="0"
  //       allow="autoplay; fullscreen"
  //       allowFullScreen
  //       title="Embedded youtube"
  //     />
  //   </div>
  // );
  const _onReady = (event) => {
    // access to player in all event handlers via event.target
    // event.target.playVideo();
  };
  const opts = {
    height,
    width,
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };
  return (
    <div className="video_container">
      <YouTube videoId={id} opts={opts} onReady={_onReady} />
    </div>
  );
};

YoutubePlayer.propTypes = {
  embedId: PropTypes.string.isRequired,
};

export default YoutubePlayer;
