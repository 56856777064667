import { DEFAULT_PROFILE } from 'data/config/profiles'; // import { getUrl } from 'core/data-and-assets/DataAssetsUtil';
//import { navigateAfterClick } from 'pages/choose-profile/chooseProfileUtil';

/**
 * Choose profile page buttons configuration
 *
 * @param {object} actions - store connected actions
 */

export var get = function get() {
  function onClick(selectedProfile, actions, thenNavigateTo) {
    navigateAfterClick(selectedProfile, actions, thenNavigateTo);
  }

  return [// {
    //     value: PROFILE_EXPOSANT, // reference for the rest of the app
    //     label: 'profile.'+PROFILE_EXPOSANT,
    //     props: {
    //         style: {
    //             backgroundColor : '#b80e80',
    //         },
    //     },
    //     onClick: onClick,
    // },
    // {
    //     value: PROFILE_VISITEUR, // reference for the rest of the app
    //     label: 'profile.'+PROFILE_VISITEUR,
    //     props: {
    //         style: {
    //             backgroundColor : '#8a0a60',
    //         },
    //     },
    //     onClick: onClick,
    // },
    // {
    //     value: PROFILE_PRESSE, // reference for the rest of the app
    //     label: 'profile.'+PROFILE_PRESSE,
    //     props: {
    //         style: {
    //             backgroundColor : '#310237',
    //         },
    //     },
    //     onClick: onClick,
    // }
  ];
};
/**
 *  + AdBanner is displayed on ChooseProfilePage
 *  + Ad config depends on current user profile
 *  => On first display AdBanner cannot display anything
 *
 *  This constant declares which profile to use in this case (only for AdBanner component)
 */

export var DEFAULT_AD_PROFILE = DEFAULT_PROFILE;