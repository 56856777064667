// Codifications that can be retrieved using web service /getCodifs.htm
// See ticket SAN-15
// See https://mobile-spot.atlassian.net/wiki/spaces/FR/pages/18677787/UserData+Networking+WebServices+request
var CODIFICATIONS_SOURCES = {
  EXHIBIS: 'EXHIBIS'
};
var CODIFICATIONS_ENTITIES = {
  INDIVIDUEVT: 'IndividuEvt'
};
var CODIFICATIONS_NAMES = {
  EVENEMENTFONCTION: 'EvenementFonction',
  CIVILITE: 'Civilite'
};
var CODIFICATION_FONCTION = {
  sourceCode: CODIFICATIONS_SOURCES.EXHIBIS,
  entityCode: CODIFICATIONS_ENTITIES.INDIVIDUEVT,
  nameField: CODIFICATIONS_NAMES.EVENEMENTFONCTION
};
var CODIFICATIONS_TO_AUTOMATICALLY_RETRIEVE = [CODIFICATION_FONCTION];
var FETCH_ON_LOGIN_SUCCESS = false;
module.exports = {
  CODIFICATION_FONCTION: CODIFICATION_FONCTION,
  CODIFICATIONS_TO_AUTOMATICALLY_RETRIEVE: CODIFICATIONS_TO_AUTOMATICALLY_RETRIEVE,
  FETCH_ON_LOGIN_SUCCESS: FETCH_ON_LOGIN_SUCCESS
};