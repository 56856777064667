// Conf
import config from 'data/config/config';

import {
  DATA_TYPE_PLACES,
  DATA_TYPE_FAVORITE_POSITIONS,
  DATA_TYPE_BRAND_CATEGORIES,
  DATA_TYPE_EVENT_CATEGORIES,
  DATA_TYPE_HAPPENINGS,
  DATA_TYPE_EVENTS,
  DATA_TYPE_EXHIBITORS,
  DATA_TYPE_PARTICIPANTS,
  DATA_TYPE_SPEAKERS,
  DATA_TYPE_GMAP_PLACES,
  CATEGORIES_DATA_TYPE,
  NON_CATEGORIES_DATA_TYPE,
  VALID_DATA_TYPES,
  CATEGORIES_MAPPING,
  getExhibitorGaiaId,
  getParticipantExhibitorGaiaId,
} from 'data/config/dataConfig';

import { SINGLE_ITEM_DATATYPES } from 'data/config/ficheConfig';

import { elementPropsGetters, shouldRedirectToMap, shouldRedirectToExternalLink, DISABLE_FAVORITE_ICON, DISABLE_FAVORITE_BY_DATATYPE } from 'data/config/listConfig';
import { groupItems } from 'data/config/sortConfig';

// App modules
import { setCurrent as setCurrentLang, get as getLabels } from 'src/core/Lang';
import { get as getProfile } from 'src/core/Profile';
import * as Db from 'src/core/data-and-assets/Db';
import * as Query from 'src/core/query/Query';
import { search, searchPlaces, applySearchOnSingleDataType } from 'src/core/search/Search';
import * as Favorites from 'src/core/favorites/Favorites';
import { HISTORY_ACTIONS, getCurrentState } from 'src/core/navigation/History';
import { parseSpecialParameters } from 'src/core/navigation/Router';
import * as Notes from 'src/core/notes/Notes';
import * as LoginService from 'src/core/login/LoginService';
import { isSessionValid } from 'src/core/login/LoginService';
import * as UserDataService from 'src/core/user-data/UserDataService';
import * as ParticipantsService from 'src/core/participants/ParticipantsService';
import {
  getContact,
  setFormFields,
  setContext,
  saveContacts,
} from 'src/core/klipso-leads/KlipsoLeadsData';
import {getAgendaProps}  from 'src/core/util/JsTools';
import NotificationLevels from 'src/components-standalone/notifications/NotificationLevels';
import * as Contacts from 'src/core/contacts/Contacts';
import { scanNewContact } from 'src/core/contacts/Contacts';
import { WS_ERRORS } from 'src/core/webservices/WS_ERRORS';

import Pages from 'src/pages/Pages';
import { DATA_TYPE_TO_PAGE_KEY } from 'src/pages/dataToPageMapping';

import {
  KLIPSO_LEADS_PAGE_KEY,
  LIST_GROUPS_PAGE_KEY,
  LIST_PAGE_KEY,
  LOGIN_PAGE_KEY,
  MOBIGEO_PAGE_KEY,
  GOOGLE_MAP_PAGE_KEY,
  SEARCH_PAGE_KEY,
  SYNOPTIC_AGENDA_PAGE_KEY,
  SEARCH_TAIGA_PAGE_KEY,
  USER_DATA_PAGE_KEY,
} from 'src/pages/pagesKeys';

import { TITLE_POI } from 'data/config/mobigeoConfig';
import { convertEntryForMap } from 'src/pages/mobigeo/mobigeoUtil';
import {
  getEndPosition,
  getPmr,
  getStartPosition,
  setStartPosition,
  setEndPosition,
  setPmr,
} from 'src/core/navigation/CurrentRoute';

import {
  SHOW_DETAIL_CONTRIBUTION_DIALOG,
  HIDE_DETAIL_CONTRIBUTION_DIALOG,
  HIDE_IMAGE_UPLOAD_DIALOG,
  SHOW_IMAGE_UPLOAD_DIALOG,
  HIDE_INPUT_MODAL_DIALOG,
  SHOW_INPUT_MODAL_DIALOG,
  DATA_ASSETS_UPDATING,
  DATA_ASSETS_UPDATED,
  UPDATER_INITIALIZED,
  MOBIGEO_RELOAD,
  GOOGLE_MAP_RELOAD,
  GOOGLE_MAP_RESET,
  GOOGLE_MAP_SHOW_PLACE,
  GOOGLE_MAP_LOADED,
  MOBIGEO_LOADED,
  GOOGLE_MAP_IS_RESTARTING,
  MOBIGEO_IS_RESTARTING,
  MOBIGEO_USER_LOCATED,
  MOBIGEO_USER_UNLOCATED,
  SHOW_MOBIGEO_ITINERARY,
  DISPATCH_ITINERARY,
  MAP_ZOOM_ON_ZONE,
  ITINERARY_API_CALLED,
  MOBIGEO_ERROR_THROWN,
  MAP_FAVORITE_CREATED,
  MAP_FAVORITE_SHARED,
  GEOGROUP_PSEUDO_SET,
  GEOGROUP_GROUP_CREATED,
  GEOGROUP_GROUP_JOINED,
  GEOGROUP_GROUP_QUITTED,
  REQUEST_LOCATION_CAPABILITIES,
  STOP_LOCATION,
  TOGGLE_LOCATION_STATUS,
  TOGGLE_LOCATION_CAPABILITY_STATUS,
  TOGGLE_PMR_STATUS,
  SHOW_SEARCH_PLACE_DIALOG,
  HIDE_SEARCH_PLACE_DIALOG,
  SET_SEARCH_PLACE_DIALOG_CANCELABLE,
  PLACE_SEARCH_PERFORMED,
  SEARCHED_PLACE_SELECTED,
  CLEAR_PLACE_RESULTS,
  NAVIGATE,
  NAVIGATE_BACK,
  NAVIGATE_TO_ITEMS,
  HAS_NAVIGATED,
  ITEM_BEING_FETCHED,
  ITEM_FETCHED,
  // ITEMS_FETCHED,
  LISTS_FETCHED,
  GROUPED_ITEMS_FETCHED,
  PROFILE_CHANGED,
  LANG_CHANGED,
  SHOW_DISCLAIMER,
  SHOW_LANG_DIALOG,
  HIDE_LANG_DIALOG,
  SHOW_SHARE_DIALOG,
  HIDE_SHARE_DIALOG,
  PERFORM_LOGIN,
  SET_LOGIN_STATUS,
  USER_DATA_UPDATED,
  UPDATE_USER_DATA_REQUEST_STATUS,
  UPDATE_PAGE_STATE,
  FETCH_NOTES,
  TOGGLE_FAVORITE,
  ALL_FAVORITES_DELETED,
  FETCH_FAVORITES,
  SET_FAVORITES_SYNCHRONIZATION_STATUS,
  SYNCHRO_FAVORITES_ICON_CLICKED,
  SET_CODE_IDENTIFICATION,
  SET_TEMPORARY_CODE_IDENTIFICATION,
  SHOW_FAVORITES_CODE_DIALOG,
  HIDE_FAVORITES_CODE_DIALOG,
  FETCH_FAVORITES_CODE,
  SYNC_WITH_FAVORITES_CODE,
  SET_FAVORITES_CODE_SYNC_STEP,
  WINDOW_RESIZED,
  SEARCH_PERFORMED,
  CLEAR_SEARCH_RESULTS,
  SET_SEARCH_FIELD_VISIBLE,
  TOGGLE_MENU,
  SHOW_DATA_LIST_DIALOG,
  HIDE_DATA_LIST_DIALOG,
  CONFIG_JSON_LOADED,
  POLL_CONFIG_UPDATED,
  POLL_CONFIG_LOADED,
  SET_POLL_ID,
  SET_POLL_CODE,
  VALIDATE_POLL,
  VALIDATE_POLL_CODE,
  SHOW_POLL_DIALOG,
  HIDE_POLL_DIALOG,
  GO_TO_NEXT_POLL_STEP,
  GO_TO_PREVIOUS_POLL_STEP,
  SUBMIT_POLL,
  SET_POLL_STEP,
  SET_POLL_ERROR,
  SET_POLL_PAGE,
  SET_POLL_MARK,
  SET_POLL_COMMENT,
  SET_POLL_CHOICE,
  SET_POLL_MULTIPLE,
  FETCH_CONTRIBUTIONS_FEED,
  CONTRIBUTIONS_FEED_LOADED,
  SET_CONTRIBUTIONS_FEED_ERROR,
  ACTIVATE_CONTRIBUTIONS_REFRESH,
  FETCH_SOCIAL_FEED,
  SOCIAL_FEED_LOADED,
  SET_SOCIAL_FEED_ERROR,
  SHOW_INTERSTICIEL,
  HIDE_INTERSTICIEL,
  SHOW_INTERSTICIEL_CLOSE_BUTTON,
  HIDE_INTERSTICIEL_CLOSE_BUTTON,
  SHOW_FULL_LOADER,
  HIDE_FULL_LOADER,
  AD_CLICKED,
  LINK_CLICKED,
  SHOW_NOTIF,
  EDIT_NOTIF,
  REMOVE_NOTIF,
  FLIGHT_CLICKED,
  SEND_APPOINTMENT_REQUEST,
  APPOINTMENT_REQUEST_SENT,
  APPOINTMENT_REQUEST_SEND_RESULT,
  CONTACT_REQUEST_PERFORMED,
  REAL_TIME_CONNECTED,
  REAL_TIME_DISCONNECTED,
  SHOW_FILTER_DIALOG,
  HIDE_FILTER_DIALOG,
  FILTER_TOP_CAT_SELECTED,
  FILTER_CATEGORY_TOGGLE,
  FILTER_RESET,
  SHOW_NOTE_MODAL,
  HIDE_NOTE_MODAL,
  NOTE_SAVED,
  GET_NOTE,
  EXPORT_NOTES,
  SYNOPTIC_AGENDA_TAB_INDEX_UPDATE,
  KEYBOARD_TOGGLED,
  AD_SWAP,
  DOCUMENT_VISIBLE,
  SHOW_FORM_MODAL,
  HIDE_FORM_MODAL,
  KLIPSOLEADS_SET_DISCLAIMER_STATUS,
  KLIPSOLEADS_REGISTER_SUCCESS,
  KLIPSOLEADS_SET_SORTED_BY_COMPANY,
  KLIPSOLEADS_CONTACTS_UPDATED,
  KLIPSOLEADS_SET_SYNC_ONGOING,
  KLIPSOLEADS_RESET_LICENCE,
  BADGE_SCAN_WITHOUT_RESULT,
  TAIGA_SEARCH_ONGOING,
  TAIGA_SEARCH_PERFORMED,
  TAIGA_SEARCH_CLEARED,
  CONTACT_SAVED_TO_DEVICE,
  CONTACTS_SCAN_STARTED,
  CONTACTS_UPDATED,
  CONTACTS_SCAN_UNAUTHORISED,
  CODIFICATIONS_FETCHED,
  SHOW_ONE_POI_ON_MOBIGEO_WITHOUT_NAVIGATION,
  SHOW_ONE_POI_ON_GOOGLE_MAP_WITHOUT_NAVIGATION,
  PERFORM_LOGIN_KLIPSO_SSO,
  SET_LOGIN_SSO_STATUS,
  FETCH_HAPPENINGS_FEED,
  FETCH_GMAP_PLACES,
  SHOW_MODAL_PRIVACY_AGREEMENT,
  VALIDATE_MODAL_PRIVACY_AGREEMENT,
} from 'src/store/actionTypes';
import STATUS from './fetchStatuses';
import { openUrl } from 'src/core/util/JsTools';
import { DATA_TYPE_LIVESTREAM } from '../../data/config/dataConfig';

import { MESSAGE_DISMISSED } from 'src/pages/inbox/inboxReducer';

import { isCordovaContext } from 'src/core/util/browser';

const SIMULATED_FETCH_LATENCY = 0;

const LOG_PREF = '[actions] ';

/**
 * Navigate none
 * @type {String}
 */
export const withoutNavigation = (type, pageProps) => (dispatch) => {
  dispatch({
    type,
    options: pageProps,
  });
};

/**
 * Navigate forward
 * @type {String}
 */
export const navigate = (pageKey, pageProps, historyAction) => (dispatch) => {
  // Special case for FilterDialog
  if (pageProps && pageProps.filterEnabled) {
    if (
      getCurrentState() &&
      getCurrentState().pageProps &&
      getCurrentState().pageProps.filterEnabled
    ) {
      // Keep only one filter result in history
      historyAction = HISTORY_ACTIONS.REPLACE;
    }
  }
  if (!historyAction) {
    historyAction = HISTORY_ACTIONS.PUSH;
  }

  dispatch({
    type: NAVIGATE,
    pageKey,
    options: pageProps,
    historyAction,
  });
};

/**
 * Navigate backward
 * @type {String}
 */
export const navigateBack = () => ({
  type: NAVIGATE_BACK,
});

export function navigateToHome() {
  const home = config.getHomePage(getProfile());
  return navigate(home.pageKey, home.props);
}

export const navigateToItems = (items, dataType, parentId, parentDataType) => ({
  type: NAVIGATE_TO_ITEMS,
  items,
  dataType,
  parentId,
  parentDataType,
});

/**
 * Navigation is done
 */
export const hasNavigated = (pageKey, pageProps, previousPageKey) => ({
  type: HAS_NAVIGATED,
  pageKey,
  pageProps,
  previousPageKey,
});

/**
 * Allows to redirect from parameters (e.g from data or ad configuration)
 *
 * @param  {object} params such as
 *                       { page: 'list', dataType: 'exhibitors'|'event_categories'|...}
 *                       { page: 'Mobigeo', type: 'exhibitors'|..., originalIds: string array}
 *                       { page: pageKey, ...props}
 * @param  {object} actions
 */
export function applyRedirect(params) {
  // Navigate to list
  if (!params.page || params.page === 'list') {
    return navigate(LIST_PAGE_KEY, {
      inputs: [{ dataType: params.dataType }],
    });

    // Show POIs on map
  }
  // TO DO ADD showAllPoisOnGoogleMap
  if (params.page === 'mobigeo') {
    const dataType = params.type;
    const { originalIds } = params;

    if (!dataType || !originalIds || Array.isArray(originalIds) !== true) {
      console.error('Missing lump.link information to show POI.', dataType, originalIds);

      // FIXME: no action?

      return;
    }
    return showAllPoisOnMobigeo({
      [dataType]: originalIds.map((originalId) => ({ id: originalId })),
    });

    // Navigate to any other page
  }
  if (params.page === 'googleMap' && !isCordovaContext()) {
    return;
  }
  
  if (params.page === 'googleMap' && isCordovaContext()) {
    const dataType = params.type;
    const { originalIds } = params;

    if (!dataType || !originalIds || Array.isArray(originalIds) !== true) {
      console.error('Missing lump.link information to show POI.', dataType, originalIds);

      // FIXME: no action?

      return;
    }
    return showAllPoisOnGoogleMap({
      [dataType]: originalIds.map((originalId) => ({ id: originalId })),
    });

    // Navigate to any other page
  }
  const pageKey = params.page;
  // Remove page key param
  const _params = { ...params };
  delete _params.page;

  return navigate(pageKey, parseSpecialParameters(_params, pageKey));
}

/**
 * Change language
 * @param  {string} lang
 */
export const setLanguage = (lang) => {
  const labels = setCurrentLang(lang);
  return {
    type: LANG_CHANGED,
    language: lang,
    labels,
  };
};

/**
 * Data and assets update is about to start
 * @type {String}
 */
export const dataAssetsUpdating = (files, data) => ({
  type: DATA_ASSETS_UPDATING,
  files,
  data,
});

/**
 * @param {array}
 * @param {array}
 */
export const dataAssetsUpdated = (updatedTables, updatedAssets) => ({
  type: DATA_ASSETS_UPDATED,
  tables: updatedTables,
  assets: updatedAssets,
});

/**
 * Updater has initialized, so assets versions are ready to be queried
 */
export const updaterInitialized = () => ({
  type: UPDATER_INITIALIZED,
});

/**
 * MogiGeo has successfully loaded and displayed the map for a dataset
 * @type {String}
 */
export const mobigeoLoaded = () => ({
  type: MOBIGEO_LOADED,
});


/**
 * Google Map has successfully loaded and displayed the map for a dataset
 * @type {String}
 */
 export const googleMapLoaded = () => ({
  type: GOOGLE_MAP_LOADED,
});

/**
 * Must reload the mobigeo
 */
export const mobigeoReload = () => ({
  type: MOBIGEO_RELOAD,
});

/**
 * Must reload the google map
 */
 export const googleMapReload = () => ({
  type: GOOGLE_MAP_RELOAD,
});

/**
 * Must reload the google map
 */
export const googleMapShowPlace = (value) => ({
  type: GOOGLE_MAP_SHOW_PLACE,
  value,
});

/**
 * Must reset the google map
 */
 export const googleMapReset = (value) => ({
  type: GOOGLE_MAP_RESET,
  value
});

/**
 * Google map has successfully loaded and displayed the map for a dataset
 * @type {String}
 */
 export const googleMapIsRestarting = () => ({
  type: GOOGLE_MAP_IS_RESTARTING,
});

/**
 * MogiGeo has successfully loaded and displayed the map for a dataset
 * @type {String}
 */
export const mobigeoIsRestarting = () => ({
  type: MOBIGEO_IS_RESTARTING,
});

/**
 * User has a position
 */
export const mobigeoUserLocated = () => ({
  type: MOBIGEO_USER_LOCATED,
});

/**
 * User has a no position
 */
export const mobigeoUserUnlocated = () => ({
  type: MOBIGEO_USER_UNLOCATED,
});

/**
 * Start saved from url itinerary an itinerary to a POI
 */
export const startSavedFromUrlItinerary = () => (dispatch) => {
  // initialization of itinerary on map ready
  const start = getStartPosition();
  const end = getEndPosition();
  const pmr = getPmr();
  if (start && end && window.MobiGeo) {
    const titleData = Query.get(start.zone, TITLE_POI.title);
    const idRelated = titleData.lump.floors[parseInt(start.floor) + 1];
    const subTitleData = Query.get(idRelated, TITLE_POI.subTitle);
    start.title = titleData.title + ' - ' + subTitleData.title;
    window.MobiGeo.Favorite.create(start, (err, startPOI) => {
      if (startPOI) {
        dispatch(
          showMobigeoItinerary(startPOI, convertEntryForMap(end), {
            userType: pmr ? 'PMR' : 'all',
          })
        );
        window.MobiGeo.Map.POI.hide([startPOI]);
        // remove from favorite after create position on mobigeo
        window.MobiGeo.Favorite.remove(parseInt(startPOI.id, 10));
        Favorites.toggle(startPOI.id, 'favorites');

        setStartPosition(null);
        setEndPosition(null);
        setPmr(null);
      } else {
        console.error(err);
      }
    });
  }
};

/**
 * Display an itinerary to a POI
 */
export const showMobigeoItinerary = (start, dest, options) => ({
  type: SHOW_MOBIGEO_ITINERARY,
  start,
  dest,
  options,
});

/**
 * dispatch an itinerary to a POI
 */
export const dispatchItinerary = (poi) => ({
  type: DISPATCH_ITINERARY,
  poi,
});

/**
 * Display a modal allowing the search for a POI (to show on map or to compute a route)
 */
export const showSearchPlaceDialog = (type) => ({
  type: SHOW_SEARCH_PLACE_DIALOG,
  searchType: type,
});

/**
 * Hide the modal allowing the user to choose start/destination
 */
export const hideSearchPlaceDialog = () => ({
  type: HIDE_SEARCH_PLACE_DIALOG,
});

// @see searchPlaceDialogMiddleware
export const setSearchPlaceDialogCancelable = (value) => ({
  type: SET_SEARCH_PLACE_DIALOG_CANCELABLE,
  value,
});

/**
 * Show POIs on mobigeo
 *
 * NB: used to be a specific action `SHOW_ALL_POIS_ON_MAP`.
 * Now that the url must be aware of the POI to show, `navigate` action is used
 */
export const showAllPoisOnMobigeo = (pois) => navigate(MOBIGEO_PAGE_KEY, { pois });

/**
 * Show a single POI on mobigeo
 *
 * NB: used to be a specific action `SHOW_ONE_POI_ON_MAP`.
 * Now that the url must be aware of the POI to show, `navigate` action is used
 */
export const showOnePoiOnMobigeo = (poi) => navigate(MOBIGEO_PAGE_KEY, { poi });

/**
 * Show a single POI on mobigeo
 *
 * NB: used to be a specific action `SHOW_ONE_POI_ON_MAP`.
 * Now that the url must be aware of the POI to show
 */
export const showOnePoiOnMobigeoWithoutNavigation = (poi) =>
  withoutNavigation(SHOW_ONE_POI_ON_MOBIGEO_WITHOUT_NAVIGATION, { poi });

/**
 * Set custom POI styles on mobigeo
 * @param  {array} data
 */
export const showCustomPoiStateOnMobigeo = (data) => navigate(MOBIGEO_PAGE_KEY, { customPoiState: data });



/**
 * Show POIs on Google map
 *
 * NB: used to be a specific action `SHOW_ALL_POIS_ON_MAP`.
 * Now that the url must be aware of the POI to show, `navigate` action is used
 */
 export const showAllPoisOnGoogleMap = (pois) => navigate(GOOGLE_MAP_PAGE_KEY, { pois });

 /**
  * Show a single POI on Google map
  *
  * NB: used to be a specific action `SHOW_ONE_POI_ON_MAP`.
  * Now that the url must be aware of the POI to show, `navigate` action is used
  */
 export const showOnePoiOnGoogleMap = (poi) => navigate(GOOGLE_MAP_PAGE_KEY, { poi });
 
 /**
  * Show a single POI on Google
  *
  * NB: used to be a specific action `SHOW_ONE_POI_ON_MAP`.
  * Now that the url must be aware of the POI to show
  */
 export const showOnePoiOnGoogleMapWithoutNavigation = (poi) =>
   withoutNavigation(SHOW_ONE_POI_ON_GOOGLE_MAP_WITHOUT_NAVIGATION, { poi });
 
 /**
  * Set custom POI styles on Google
  * @param  {array} data
  */
 export const showCustomPoiStateOnGoogleMap = (data) => navigate(GOOGLE_MAP_PAGE_KEY, { customPoiState: data });

/**
 * Route to map to focus on a specific zone and floor
 */
export const mapZoomOnZone = (zone, floor) => ({
  type: MAP_ZOOM_ON_ZONE,
  zone,
  floor,
});

export const itineraryApiCalled = (destination) => ({
  type: ITINERARY_API_CALLED,
  destination,
});

export const mobigeoErrorThrown = (errorCode, mobigeoModuleName, additionalInfo) => ({
  type: MOBIGEO_ERROR_THROWN,
  errorCode,
  module: mobigeoModuleName,
  additionalInfo,
});

export const mapFavoriteCreated = () => ({
  type: MAP_FAVORITE_CREATED,
});

export const mapFavoriteShared = () => ({
  type: MAP_FAVORITE_SHARED,
});

export const setCodeIdentification = (code) => ({
  type: SET_CODE_IDENTIFICATION,
  code,
});

export const setTemporaryCodeIdentification = (code) => ({
  type: SET_TEMPORARY_CODE_IDENTIFICATION,
  code,
});

export const showFavoritesCodeDialog = () => ({
  type: SHOW_FAVORITES_CODE_DIALOG,
});

export const hideFavoritesCodeDialog = () => ({
  type: HIDE_FAVORITES_CODE_DIALOG,
});

export const fetchFavoritesCode = () => ({
  type: FETCH_FAVORITES_CODE,
});

export const syncWithFavoritesCode = () => ({
  type: SYNC_WITH_FAVORITES_CODE,
});

export const setFavoritesCodeSyncStep = (step) => ({
  type: SET_FAVORITES_CODE_SYNC_STEP,
  step,
});

/**
 * GEOGROUP actions
 */

export const geogroupPseudoSet = (user) => ({
  type: GEOGROUP_PSEUDO_SET,
  user,
});

export const geogroupGroupCreated = (group) => ({
  type: GEOGROUP_GROUP_CREATED,
  group,
});

export const geogroupGroupJoined = (group) => ({
  type: GEOGROUP_GROUP_JOINED,
  group,
});

export const geogroupGroupQuitted = (group) => ({
  type: GEOGROUP_GROUP_QUITTED,
  group,
});

export const toggleLocationStatus = (value) => ({
  type: TOGGLE_LOCATION_STATUS,
  value,
});

export const requestLocationCapabilities = () => ({
  type: REQUEST_LOCATION_CAPABILITIES,
});

export const stopLocation = () => ({
  type: STOP_LOCATION,
});

export const toggleLocationCapabilityStatus = (value) => {
  return {
    type: TOGGLE_LOCATION_CAPABILITY_STATUS,
    value,
  };
};

export const togglePMRStatus = (value) => ({
  type: TOGGLE_PMR_STATUS,
  value,
});

/**
 * Handle what happens when redirecting to an item
 * (show POI, or display dedicated page e.g ExhibitorPage)
 *
 * @param  {string} type
 * @param  {number} id
 * @param  {string} originalId
 */
export function genericItemNavigation(type, id, originalId, url) {
  if (shouldRedirectToMap(type, id)) {
    return showOnePoiOnMobigeo({ type, id, originalId });
  }
  if(shouldRedirectToExternalLink(type)) {
    if(url) {
      openUrl(url, null, "", false);
    }
    return {type: HAS_NAVIGATED};
  }
  return navigate(DATA_TYPE_TO_PAGE_KEY[type], { id: /^\d+$/.test(id) ? parseInt(id, 10) : id });
}

/**
 * Generic behaviour when a category is clicked
 * @param  {number} id
 * @param  {string} catDataType
 * @return {object}
 */
export function categoryClicked(id, catDataType) {
  const cat = Query.get(id, catDataType);

  const itemsDataType = CATEGORIES_MAPPING[catDataType];
  const childrenIds = cat.lump ? cat.lump[itemsDataType] : null;
  const childrenLength = Array.isArray(childrenIds) ? childrenIds.length : 0;
  const hasFilter = cat.lump ? cat.lump.hasFilter : null;

  // Special case: Ability to redirect to another page
  if (cat.lump && cat.lump.link) {
    return applyRedirect(cat.lump.link);
  }

  if (childrenLength > 0) {
    if (childrenLength === 1 && catDataType !== DATA_TYPE_BRAND_CATEGORIES) {
      // Cat countains a single item: directly display the item
      return genericItemNavigation(itemsDataType, childrenIds[0], null);
    }
    // Display children from this category
    if (catDataType === DATA_TYPE_EVENT_CATEGORIES) {
      // Show a list of events grouped by date
      return navigate(LIST_GROUPS_PAGE_KEY, {
        input: {
          parentId: id,
          parentType: catDataType,
          dataType: DATA_TYPE_EVENTS,
        },
      });
    }
    return navigate(LIST_PAGE_KEY, {
      locateAll: cat.lump.locateAll === 'true',
      hasFilter,
      inputs: [
        {
          parentId: id,
          parentType: catDataType,
          dataType: itemsDataType,
        },
      ],
    });
  }
  // Special 'is_all' category
  if (cat.is_all) {
    if (catDataType === DATA_TYPE_EVENT_CATEGORIES) {
      return navigate(LIST_GROUPS_PAGE_KEY, {
        input: { dataType: DATA_TYPE_EVENTS },
      });
    }
    return navigate(LIST_PAGE_KEY, {
      hasFilter,
      inputs: [
        {
          dataType: itemsDataType,
        },
      ],
    });
  }
  // Display sub-categories
  return navigate(LIST_PAGE_KEY, {
    hasFilter,
    inputs: [
      {
        id,
        dataType: catDataType,
      },
    ],
  });
}

const itemFetchStatus = (props) => ({
  type: ITEM_FETCHED,
  isFavorite: Favorites.isFavorite(props.id, props.dataType),
  ...props,
});

export const itemBeingFetched = (id, dataType) => ({
  type: ITEM_BEING_FETCHED,
  id,
  dataType,
});

let lastFetchedItem;
/**
 * Fetch a single item
 * @param  {number} id
 * @param  {string} dataType
 * @param  {array} relatedDataToSet
 * @return {object/promise}
 */
export const fetchItem = (id, dataTypeOpt, relatedDataToSet) => (dispatch) => {
  let status;
  let item;
  // TODO: check if we keep convert DATA_TYPE_LIVESTREAM to DATA_TYPE_EVENTS here
  let dataType = dataTypeOpt === DATA_TYPE_LIVESTREAM ? DATA_TYPE_EVENTS : dataTypeOpt;

  function next() {
    dispatch(itemFetchStatus({ id, dataType, item, status }));
  }

  // Check dataType
  if (VALID_DATA_TYPES.indexOf(dataType) === -1) {
    console.error(`${LOG_PREF}Cannot fetch data for type: ${dataType}`);
    status = STATUS.NO_RESULT;
    next();
    return;
  }

  window.setTimeout(() => {
    if (Db.isDataReady() !== true) {
      status = STATUS.PENDING;
    } else {
      const _relatedDataToSet =
        relatedDataToSet || Pages[DATA_TYPE_TO_PAGE_KEY[dataType]].relatedDataToFetch;

      if (SINGLE_ITEM_DATATYPES.indexOf(dataType) !== -1) {
        item = Db.getSortedAndTransformedData()[dataType][0];
        if (_relatedDataToSet) {
          item = Query.completeData(item, dataType, _relatedDataToSet);
        }
      } else {
        item = Query.get(id, dataType, _relatedDataToSet);
      }

      if (item) {
        // LOCAL DATA FOUND
        status = STATUS.FETCHED;
        lastFetchedItem = { id, dataType };

        // Fetch any additional needed data from web service call
        switch (dataType) {
          case DATA_TYPE_EXHIBITORS:
            if (
              isSessionValid() &&
              config.NETWORKING &&
              config.NETWORKING.FEATURE_ENABLED &&
              config.NETWORKING.FETCH_EXHIBITOR_PARTICIPANTS
            ) {
              // Get related contacts

              let exhibitorGaiaId = getExhibitorGaiaId(item);
              if (exhibitorGaiaId) {
                const references = item.references || {};

                // 1 - Until the webservice responds, use local data
                let items = Query.find(
                  [(p) => getParticipantExhibitorGaiaId(p) === exhibitorGaiaId],
                  DATA_TYPE_PARTICIPANTS
                );
                references[DATA_TYPE_PARTICIPANTS] = {
                  status: STATUS.PENDING,
                  items: Db.sortItems(items || [], DATA_TYPE_PARTICIPANTS),
                };
                item.references = references;

                // 2 - Call web service
                ParticipantsService.getParticipantsRelatedToAnExhibitor(exhibitorGaiaId, function({
                  error,
                  data,
                }) {
                  if (id !== lastFetchedItem.id || dataType !== lastFetchedItem.dataType) {
                    // If item has changed during the web service call, skip WS response (user has navigated to another item)
                    return;
                  }
                  references[DATA_TYPE_PARTICIPANTS] = {
                    status: STATUS.FETCHED, // an ERROR would be better?
                    items: error ? [] : data,
                  };
                  item = { ...item, ...references };
                  next();
                });
              }
            }
            break;

          case DATA_TYPE_SPEAKERS:
            if (
              isSessionValid() &&
              config.NETWORKING &&
              config.NETWORKING.FEATURE_ENABLED &&
              config.NETWORKING.FETCH_SPEAKER_PARTICIPANT
            ) {
              // Get participant data
              const references = item.references || {};

              // Local fetch
              const localData = Query.get(item.original_id, DATA_TYPE_PARTICIPANTS);
              if (localData) {
                item.references.participant = localData;
              } else {
                references.participant = { status: STATUS.PENDING };
              }

              // Remote fetch to refresh the participant data
              ParticipantsService.getParticipantsByIds([item.original_id], function({
                error,
                data,
              }) {
                if (id !== lastFetchedItem.id || dataType !== lastFetchedItem.dataType) {
                  // If item has changed during the web service call, skip WS response (user has navigated to another item)
                  return;
                }
                if (error || !data || Array.isArray(data) !== true || data.length === 0) {
                  references.participant = null;
                } else {
                  references.participant = data[0];
                }
                item = { ...item, ...references };
                next();
              });
            }
            break;

          default: // for linter
        }
      } else {
        status = STATUS.NO_RESULT;
      }

      // Fetch data from remote
      if (dataType === DATA_TYPE_PARTICIPANTS) {
        if (!item) {
          // No local data found, in that case keep 'pending' status
          status = STATUS.PENDING;
        }

        // Refresh item by fetching remote data
        ParticipantsService.search({ ids: [id] }, function({ error, data }) {
          if (error || !data || Array.isArray(data) !== true || data.length !== 1) {
            status = STATUS.NO_RESULT;
          } else {
            item = data[0];
            if (_relatedDataToSet) {
              item = Query.completeData(item, dataType, _relatedDataToSet);
            }
            status = STATUS.FETCHED;
          }
          next();
        });
      }
    }

    next();
  }, SIMULATED_FETCH_LATENCY);
};

/* `fetchItems` DOESN'T SEEM USED ANYMORE (strangely)

const itemsFetchStatus = (ids, dataType, items, status) => ({
    type: ITEMS_FETCHED,
    ids: ids,
    dataType: dataType,
    items: items,
    status: status,
    favorites: Favorites.getAll(),
});

**
 * @param  {array|string} ids (string if there is a single id)
 * @param  {string} dataType
 * @param  {array} relatedDataToSet
 * @return {object/promise}
 *
export const fetchItems = (ids, dataType, relatedDataToSet) => dispatch => {
    let status,
        items;

    // Check dataType
    if (VALID_DATA_TYPES.indexOf(dataType) === -1) {
        console.error(LOG_PREF + 'Cannot fetch data for type: ' + dataType);
        dispatch(itemsFetchStatus(ids, dataType, items, STATUS.NO_RESULT));
        return;
    }

    if (Db.isDataReady() !== true) {
        status = STATUS.PENDING;
    } else {
        let _ids;
        // Handle various input type
        if (Array.isArray(ids)) {
            _ids = ids;
        } else if (typeof ids === 'string') {
            _ids = ids.split(',');
        } else if (typeof ids === 'number') {
            _ids = [ids];
        } else {
            console.error(LOG_PREF + 'Unexpected type of argument `ids`', ids);
            _ids = [];
        }
        items = _ids.map((id) => Query.get(id, dataType, relatedDataToSet)).filter(item => item);

        if (items.length > 0) {
            items = Db.sortItems(items, dataType);
            status = STATUS.FETCHED;
        } else {
            status = STATUS.NO_RESULT;
        }
    }

    window.setTimeout(() => {
        dispatch(itemsFetchStatus(ids, dataType, items, status));
    }, SIMULATED_FETCH_LATENCY);
}; */

const listsFetchStatus = (inputs, items, status, contextualTitle, header, customStateOnMap) => ({
  type: LISTS_FETCHED,
  inputs,
  items,
  status,
  favorites: Favorites.getAll(),
  contextualTitle,
  header,
  customStateOnMap,
});

/**
 * Fetch a list of items
 * @param  {object} inputs @see ListPage propTypes for description
 * @return {array/promise}
 */
export const fetchLists = (inputs) => (dispatch) => {
  const promises = (inputs || []).map(
    (input) =>
      new Promise(function(resolve, reject) {
        // Special case: participants (fetch them using a web service)
        if (input.dataType === DATA_TYPE_PARTICIPANTS) {
          ParticipantsService.getParticipants(function(error, participants) {
            if (error) {
              console.error('Failed to fetch participants', error);
              resolve({
                dataType: input.dataType,
                status: STATUS.NO_RESULT,
                items: [],
              });
            } else {
              resolve({
                dataType: input.dataType,
                status: STATUS.FETCHED,
                items: Db.sortItems(participants, input.dataType),
              });
            }
          });

          // PBC-111 - Also refresh user data
          // (e.g if participants have been contacted from another device
          // during the ALL_PARTICIPANTS_DATA_EXPIRE_DELAY)
          UserDataService.refreshUserDataFromAPI();

          return;
        }

        let result;
        if (NON_CATEGORIES_DATA_TYPE.indexOf(input.dataType) !== -1) {
          if (input.parentId !== null && typeof input.parentId !== 'undefined') {
            // Fetch from parent category
            result = fetchContentOfCategory(input.parentId, input.parentType, input.dataType);
          } else if (Array.isArray(input.ids)) {
            result = {
              items: input.ids.map((id) => Query.get(id, input.dataType)).filter((item) => item),
              dataType: input.dataType,
              status: STATUS.FETCHED,
            };
          } else {
            // Fetch all
            result = fetchAll(input.dataType);
          }
        } else if (CATEGORIES_DATA_TYPE.indexOf(input.dataType) !== -1) {
          result = fetchSubCategories(input.id, input.dataType);
        } else {
          // fatal error
          console.error(`${LOG_PREF}Cannot fetch data for type: ${input.dataType}`);
          reject();
        }

        /*
        status[input.dataType] = result.status;
        items[input.dataType] = Db.sortItems(result.items, input.dataType);

        if (result.contextualTitle) {
            contextualTitles.push(result.contextualTitle);
        }
        header = result.header;
        customStateOnMap = result.customStateOnMap; */

        resolve({
          dataType: input.dataType,
          status: result.status,
          items: Db.sortItems(result.items || [], input.dataType),
          contextualTitle: result.contextualTitle,
          header: result.header,
          customStateOnMap: result.customStateOnMap,
        });
      })
  );

  Promise.all(promises).then((results) => {
    const status = {};
    const items = {};
    const contextualTitles = [];
    let header;
    let customStateOnMap;

    results.forEach((result) => {
      status[result.dataType] = result.status;
      items[result.dataType] = result.items;
      header = result.header;
      customStateOnMap = result.customStateOnMap;

      if (result.contextualTitle) {
        contextualTitles.push(result.contextualTitle);
      }
    });

    dispatch(
      listsFetchStatus(
        inputs,
        items,
        status,
        contextualTitles.join(' & '),
        header,
        customStateOnMap
      )
    );
  });
};

function fetchAll(dataType) {
  let status;
  let items;

  if (Db.isDataReady() !== true) {
    status = STATUS.PENDING;
  } else {
    items = Query.getAll(dataType);

    if (!items || items.length === 0) {
      status = STATUS.NO_RESULT;
    } else {
      status = STATUS.FETCHED;
    }
  }
  return {
    items,
    status,
    dataType,
  };
}

function fetchContentOfCategory(parentId, parentType, childrenDataType) {
  let status;
  let items;
  let contextualTitle;
  let header;
  let ad;
  let customStateOnMap;
  let hasSynopticAgendaButton;

  if (Db.isDataReady() !== true) {
    status = STATUS.PENDING;
  } else {
    // Get parent
    const parent = Query.get(parentId, parentType);

    if (parent) {
      contextualTitle = parent.title;
      header = parent.lump ? parent.lump.header : null;
      ad = parent.lump ? parent.lump.ad : null;
      customStateOnMap = parent.lump ? parent.lump.customStateOnMap : null;
      hasSynopticAgendaButton = parent.lump.hasSynopticAgendaButton;

      // Get children ids
      const ids = parent.lump[childrenDataType];
      if (Array.isArray(ids)) {
        // Get items
        items = ids.map((id) => Query.get(id, childrenDataType));

        // Safety net: remove items not found
        items = items.filter((item) => item);

        // Sort items
        items = Db.sortItems(items, childrenDataType);
      }
    }

    if (Array.isArray(items) !== true || items.length === 0) {
      status = STATUS.NO_RESULT;
    } else {
      status = STATUS.FETCHED;
    }
  }

  return {
    items,
    status,
    dataType: childrenDataType,
    contextualTitle,
    header,
    ad,
    customStateOnMap,
    hasSynopticAgendaButton,
  };
}

/**
 * Fetch a category's content
 * @param  {number} id
 * @param  {string} dataType
 * @return {array/promise}
 */
function fetchSubCategories(id, dataType) {
  let status;
  let items;
  let contextualTitle;
  let header;

  if (Db.isDataReady() !== true) {
    status = STATUS.PENDING;
  } else if (!Db.getData()[dataType] || Db.getData()[dataType].length === 0) {
    status = STATUS.NO_RESULT;
  } else {
    let subCategories;
    let siblingItems;

    const siblingDatatype = CATEGORIES_MAPPING[dataType];

    if (id === null || typeof id === 'undefined' || id === '') {
      // Get top categories
      items = Query.getTopCategories(dataType);
      // siblingItems = Query.find([sibling => (!sibling.lump.cats || (Array.isArray(sibling.lump.cats) && sibling.lump.cats.length === 0))], siblingDatatype);
    } else {
      // Get sub-catagories of a category
      const category = Query.get(id, dataType);

      if (category) {
        contextualTitle = category.title;
        header = category.lump.header;
        subCategories = category.lump.cats;
        const categorySiblings = category.lump[siblingDatatype];

        if (Array.isArray(subCategories) === true && subCategories.length > 0) {
          items = subCategories.map((catId) => Query.get(catId, dataType));
        }

        if (Array.isArray(categorySiblings) === true && categorySiblings.length > 0) {
          siblingItems = categorySiblings.map((siblingId) => Query.get(siblingId, siblingDatatype));
        }
      }
    }

    // Ignore empty categories
    if (Array.isArray(items)) {
      items = items.filter((item) => item && item.counter > 0);
    }

    if (Array.isArray(siblingItems)) {
      // Safety net: remove items not found
      siblingItems = siblingItems.filter((sibl) => sibl);

      if (siblingItems.length > 0) {
        items = items || [];
        items = items.concat(
          siblingItems.map((siblingItem) => ({ ...siblingItem, listType: siblingDatatype }))
        );
      }
    }

    if (Array.isArray(items) !== true || items.length === 0) {
      status = STATUS.NO_RESULT;
    } else {
      status = STATUS.FETCHED;
    }
  }

  return {
    items,
    status,
    dataType,
    contextualTitle,
    header,
  };
}

export const groupedItemsFetched = (
  input,
  groupedItems,
  status,
  dataType,
  contextualTitle,
  ad,
  hasSynopticAgendaButton
) => ({
  type: GROUPED_ITEMS_FETCHED,
  input,
  groupedItems,
  dataType,
  status,
  ad,
  contextualTitle,
  hasSynopticAgendaButton,
  favorites: Favorites.getAll(),
});

export const fetchGroupedItems = (input, relatedDataToSet) => (dispatch) => {
  let status;
  let contextualTitle;
  let ad;
  let items;
  let groupedItems;
  let hasSynopticAgendaButton;
  const { dataType } = input;

  if (Db.isDataReady() !== true) {
    status = STATUS.PENDING;
  } else if (!Db.getData()[dataType] || Db.getData()[dataType].length === 0) {
    status = STATUS.NO_RESULT;
  } else {
    let parentId;
    if (input && input.parentId) {
      parentId = typeof input.parentId === 'number' ? input.parentId : parseInt(input.parentId, 10);
    }

    if (!input || !parentId) {
      // Check synoptic button presence flag
      const allCat = Query.findOne([(cat) => !!cat.is_all], Db.getCategoryDatatype(dataType));
      if (allCat) {
        hasSynopticAgendaButton = allCat.lump.hasSynopticAgendaButton;
      }

      // Get all items
      items = Query.getAll(dataType);
    } else {
      // Get items belonging to a specific category
      const result = fetchContentOfCategory(parentId, input.parentType, dataType);
      items = result.items;
      contextualTitle = result.contextualTitle;
      ad = result.ad;
      // Synoptic button presence flag:
      hasSynopticAgendaButton = result.hasSynopticAgendaButton;
    }

    if (items.length > 0) {
      if (Array.isArray(relatedDataToSet)) {
        items = items.map((item) => Query.completeData(item, DATA_TYPE_EVENTS, relatedDataToSet));
      }

      // Execute group configuration
      groupedItems = groupItems(items, dataType);

      status = STATUS.FETCHED;
    } else {
      status = STATUS.NO_RESULT;
    }
  }

  dispatch(
    groupedItemsFetched(
      input,
      groupedItems,
      status,
      dataType,
      contextualTitle,
      ad,
      hasSynopticAgendaButton
    )
  );
};

export const profileChanged = (newProfile) => ({
  type: PROFILE_CHANGED,
  profile: newProfile,
});

export const showDisclaimer = () => ({
  type: SHOW_DISCLAIMER,
});

export const showChooseLangDialog = () => ({
  type: SHOW_LANG_DIALOG,
});
export const hideChooseLangDialog = () => ({
  type: HIDE_LANG_DIALOG,
});

export const hideInboxAlertDialog = () => ({
  type: MESSAGE_DISMISSED,
});

export const hideDetailContributionDialog = () => ({
  type: HIDE_DETAIL_CONTRIBUTION_DIALOG,
});

export const showDetailContributionDialog = () => ({
  type: SHOW_DETAIL_CONTRIBUTION_DIALOG,
});

export const hideImageUploadDialog = () => ({
  type: HIDE_IMAGE_UPLOAD_DIALOG,
});

export const showImageUploadDialog = () => ({
  type: SHOW_IMAGE_UPLOAD_DIALOG,
});

export const hideInputModaldDialog = () => ({
  type: HIDE_INPUT_MODAL_DIALOG,
});

export const showInputModaldDialog = () => ({
  type: SHOW_INPUT_MODAL_DIALOG,
});

export const startLogin = (username, password) => ({
  type: PERFORM_LOGIN,
  username,
  password,
});

export const setLoginStatus = (loggedIn, userData, error) => ({
  type: SET_LOGIN_STATUS,
  loggedIn,
  userData,
  error,
});

export const updateUserDataRequestStatus = (status) => ({
  type: UPDATE_USER_DATA_REQUEST_STATUS,
  status,
});

export const userDataUpdated = (userData) => ({
  type: USER_DATA_UPDATED,
  userData,
});

export const remoteSaveUserData = (userData) => (dispatch) => {
  UserDataService.remoteSaveUserData(userData, true, function(error) {
    if (error) {
      // FAILURE
      if (error === WS_ERRORS.AUTH) {
        setTimeout(() => {
          dispatch(navigate(LOGIN_PAGE_KEY, { nextRoute: { pageKey: USER_DATA_PAGE_KEY } }));
        }, 2000);
      }
      dispatch(updateUserDataRequestStatus({ saving: false, error }));
    } else {
      // SUCCESS
      // No need to:
      //  - show a notification (UserDataForm does it)
      //  - dispatch a status update (at lower level user data is automatically refreshed which ends updating `status`)
    }
  });
  dispatch(updateUserDataRequestStatus({ saving: true, error: null }));
};

export const updatePageState = (pageKey, props) => ({
  type: UPDATE_PAGE_STATE,
  pageKey,
  props,
});

export function toggleFavorite(id, dataType, isFav, noSync = false, source, data) {
  if(DISABLE_FAVORITE_ICON || DISABLE_FAVORITE_BY_DATATYPE.includes(dataType)){
    console.error('favorite is disabled from the config for this dataType', dataType)
    return showNotification({
      message: getLabels().common.error,
    });
  }
  if (isFav) {
    // Removing an item from favorites
    // Check if it is declared in user data
    if (UserDataService.isItemRelatedToUserData(id, dataType)) {
      return showNotification({
        message: getLabels().userData.cantRemoveItemFromFavorites,
      });
    }
  }

  Favorites.toggle(id, dataType, isFav);

  return {
    type: TOGGLE_FAVORITE,
    id,
    dataType,
    favListUpdated: true, // forced to true due to 'synchronize favorites' feature
    isFav,
    noSync,
    source,
    data,
    favorites: JSON.parse(JSON.stringify(Favorites.getAll())), // clone
  };
}

export const allFavoritesDeleted = () => ({
  type: ALL_FAVORITES_DELETED,
});

export function fetchFavorites() {
  const favorites = Favorites.getAll();

  // Merge mobigeo favorite positions into app favorites
  const favoritePositions = MobiGeo.Favorite.getAll() || [];
  favorites[DATA_TYPE_FAVORITE_POSITIONS] = favoritePositions.map((fav) => fav.id);

  // Get full data for each entry
  let status;
  const data = {};

  if (Db.isDataReady() !== true) {
    status = STATUS.PENDING;
  } else {
    function getItem(id, dataType) {
      if (dataType === DATA_TYPE_FAVORITE_POSITIONS) {
        return favoritePositions.find((fav) => fav.id === id);
      }
      return Query.get(id, dataType);
    }

    Object.keys(favorites).forEach((dataType) => {
      if (VALID_DATA_TYPES.indexOf(dataType) !== -1) {
        data[dataType] = [];
        favorites[dataType].forEach((id) => {
          const entry = getItem(id, dataType);
          if (!entry) {
            console.log(`Could not find entry ${id} of type: ${dataType}`);
          } else {
            data[dataType].push(entry);
          }
        });

        // Sort
        data[dataType] = Db.sortItems(data[dataType], dataType);
      }
    });
    status = STATUS.FETCHED;
  }
  return {
    type: FETCH_FAVORITES,
    favorites: JSON.parse(JSON.stringify(favorites)),
    data,
    status,
  };
}

export const setFavoritesSynchronizationStatus = (status) => ({
  type: SET_FAVORITES_SYNCHRONIZATION_STATUS,
  status,
});

export const synchroFavoritesIconClicked = (active) => ({
  type: SYNCHRO_FAVORITES_ICON_CLICKED,
  active,
});

export const windowResized = () => ({
  type: WINDOW_RESIZED,
  timestamp: new Date().getTime(),
});

// Search through a single data type
export function searchOnASingleDataType(value, pageKey, dataType, data) {
  const { results, count } = applySearchOnSingleDataType(dataType, data, value, pageKey);
  return {
    type: SEARCH_PERFORMED,
    pageKey,
    searched: value,
    status: STATUS.FETCHED,
    results: { [dataType]: results },
    totalCount: count,
    favorites: Favorites.getAll(),
    contextualSearch: false,
  };
}

// Search
export function performSearch(value, pageKey, dataTypes, contextualSearch) {
  let searchDataTypes = null;
  let searchResult;

  // add children sibling type if category type
  if (dataTypes && Array.isArray(dataTypes) && dataTypes.length > 0) {
    searchDataTypes = [...dataTypes];
    searchDataTypes.forEach((dataType) => {
      if (NON_CATEGORIES_DATA_TYPE.indexOf(dataType) === -1) {
        const siblingType = CATEGORIES_MAPPING[dataType];
        if (
          searchDataTypes.indexOf(siblingType) === -1 &&
          VALID_DATA_TYPES.indexOf(siblingType) > -1
        ) {
          searchDataTypes.push(siblingType);
        }
      }

      if (NON_CATEGORIES_DATA_TYPE.indexOf(dataType) > -1) {
        const catTypes = Object.keys(CATEGORIES_MAPPING);
        const siblingCatType = catTypes.find((catType) => CATEGORIES_MAPPING[catType] === dataType);
        if (
          siblingCatType &&
          searchDataTypes.indexOf(siblingCatType) === -1 &&
          VALID_DATA_TYPES.indexOf(siblingCatType) > -1
        ) {
          searchDataTypes.push(siblingCatType);
        }
      }
    });
  }

  // PERFORM
  searchResult = search(value, searchDataTypes, pageKey);

  // clean results
  const resultTypes = Object.keys(searchResult.data);
  const data = {};
  resultTypes.forEach((dataType) => {
    let _items = searchResult.data[dataType] || [];

    // category type
    if (NON_CATEGORIES_DATA_TYPE.indexOf(dataType) === -1) {
      const siblingType = CATEGORIES_MAPPING[dataType];
      _items = _items.filter(
        (item) =>
          item.lump &&
          ((item.lump[siblingType] && item.lump[siblingType].length > 0) ||
            (item.lump.cats && item.lump.cats.length > 0))
      );
    }

    if (_items.length > 0) {
      data[dataType] = _items;
    }
  });
  return {
    type: SEARCH_PERFORMED,
    pageKey,
    searched: value,
    status: searchResult.status,
    results: data,
    totalCount: searchResult.totalCount,
    favorites: Favorites.getAll(),
    contextualSearch,
  };
}

export const clearSearchResults = (pageKey) => ({
  type: CLEAR_SEARCH_RESULTS,
  pageKey,
});

export const setSearchFieldVisible = (pageKey, isVisible) => ({
  type: SET_SEARCH_FIELD_VISIBLE,
  pageKey,
  isVisible,
});

// Place search
export function performPlaceSearch(string, searchType) {
  const searchResult = searchPlaces(string);
  return {
    type: PLACE_SEARCH_PERFORMED,
    searched: string,
    searchType,
    status: searchResult.status,
    results: searchResult.data,
    totalCount: searchResult.totalCount,
  };
}

export function searchedPlaceSelected(searchType, entry) {
  if (!entry.text) {
    const _elementPropsGetters = elementPropsGetters(entry.type);
    const item = Query.get(entry.id, entry.type);
    if (item && _elementPropsGetters) {
      entry.text = _elementPropsGetters.text(item);

      // textMinor is not mandatory
      if (typeof _elementPropsGetters.textMinor === 'function') {
        entry.textMinor = _elementPropsGetters.textMinor(item);
      }
    }
  }
  return {
    type: SEARCHED_PLACE_SELECTED,
    searchType,
    entry,
  };
}

export const clearPlaceResults = (type) => ({
  type: CLEAR_PLACE_RESULTS,
  status: STATUS.FETCHED,
  searchType: type,
});

function toggleMenu(pageKey, isOpen) {
  if (!pageKey || typeof pageKey !== 'string') {
    console.error(`${LOG_PREF}Cannot ${isOpen ? 'open' : 'close'} menu, missing page key`, pageKey);
    return;
  }
  return {
    type: TOGGLE_MENU,
    pageKey,
    isOpen,
  };
}
export const openMenu = (pageKey) => toggleMenu(pageKey, true);
export const closeMenu = (pageKey) => toggleMenu(pageKey, false);

export function showDataListDialog(idsByType, placeId, pageKey) {
  const items = {};
  Object.keys(idsByType).forEach((dataType) => {
    items[dataType] = idsByType[dataType].map((id) => Query.get(id, dataType)).filter((itm) => itm);
    items[dataType].forEach((item) => {
      item.contextualPlaceId = placeId;
    });
    items[dataType] = Db.sortItemsForDataListDialog(items[dataType], dataType);
  });

  let title;
  let place;
  if (typeof placeId === 'number') {
    place = Query.get(placeId, DATA_TYPE_PLACES);
    if (place) {
      title = place.label;
    }
  }

  return {
    type: SHOW_DATA_LIST_DIALOG,
    items,
    placeId,
    title,
    pageKey,
    favorites: Favorites.getAll(),
  };
}

export const hideDataListDialog = () => ({
  type: HIDE_DATA_LIST_DIALOG,
});

export const showShareDialog = (name, description, url, image) => ({
  type: SHOW_SHARE_DIALOG,
  name,
  description,
  url,
  image,
});

export function fetchNotes() {
  const notes = Notes.getAll();

  // Get full data for each entry
  let status;
  const data = {};

  if (Db.isDataReady() !== true) {
    status = STATUS.PENDING;
  } else {
    function getItem(id, dataType) {
      return Query.get(id, dataType);
    }
    Object.keys(notes).forEach((dataType) => {
      if (VALID_DATA_TYPES.indexOf(dataType) !== -1) {
        data[dataType] = [];
        notes[dataType].forEach((id) => {
          const entry = getItem(id, dataType);
          if (!entry) {
            console.warn(`Could not find entry ${id} of type: ${dataType}`);
          } else {
            data[dataType].push(entry);
          }
        });
        // Sort
        data[dataType] = Db.sortItems(data[dataType], dataType);
      }
    });
    status = STATUS.FETCHED;
  }
  return {
    type: FETCH_NOTES,
    notes,
    data,
    status,
  };
}

export const saveNote = (itemTitle, itemId, note, dataType) => (dispatch) => {
  Notes.saveNote(itemTitle, itemId, note, dataType);
  dispatch({
    type: NOTE_SAVED,
    note,
    notes: Notes.getAll(),
  });
};

export function getNote(itemId, dataType) {
  const note = Notes.getNote(itemId, dataType);
  return {
    type: GET_NOTE,
    note,
  };
}

export const deleteNote = (itemId, dataType) => (dispatch) => {
  Notes.deleteNote(itemId, dataType);
  dispatch(fetchNotes());
  dispatch(hideNoteModal());
};

export const exportNotes = (notes, notesData) => (dispatch) => {
  dispatch({
    type: EXPORT_NOTES,
    notes,
    notesData,
  });
};

export const showNoteModal = (itemTitle, itemId, dataType, liEl) => (dispatch) => {
  itemId = typeof itemId === 'string' ? parseInt(itemId, 10) : itemId;
  dispatch(getNote(itemId, dataType));
  dispatch({
    type: SHOW_NOTE_MODAL,
    itemTitle,
    itemId,
    dataType,
    liEl,
  });
};
export const hideNoteModal = () => ({
  type: HIDE_NOTE_MODAL,
});

export const showFormModal = (form, formAction, formActionPayload, liEl) => (dispatch) => {
  dispatch({
    type: SHOW_FORM_MODAL,
    form,
    formAction,
    formActionPayload,
    liEl,
  });
};
export const hideFormModal = () => ({
  type: HIDE_FORM_MODAL,
});

export const hideShareDialog = () => ({
  type: HIDE_SHARE_DIALOG,
});

export const saveContactToDevice = (item) => (dispatch) => {
  Contacts.saveContactToDevice(item);
  dispatch({
    type: CONTACT_SAVED_TO_DEVICE,
  });
};

export function showFilterDialog(dataTypes) {
  // Only one data type is handled for now
  const catDataType = Db.getCategoryDatatype(dataTypes[0]);

  let topCats = Query.getSystemCategories(catDataType);
  if (Array.isArray(topCats)) {
    // Remove the "All items" category, and any empty top categories
    topCats = topCats.filter((cat) => !cat.is_all && cat.counter > 0);
    // Sort
    Db.sortItems(topCats, catDataType);
  } else {
    topCats = [];
  }

  return {
    type: SHOW_FILTER_DIALOG,
    topCats,
    dataType: catDataType,
  };
}

export const hideFilterDialog = () => ({
  type: HIDE_FILTER_DIALOG,
});

export const filterTopCatSelected = (catId, dataType, tabIndex) => ({
  type: FILTER_TOP_CAT_SELECTED,
  catId,
  dataType,
  tabIndex,
});

export const filterCategoryToggle = (catId, dataType, toRemove) => ({
  type: FILTER_CATEGORY_TOGGLE,
  catId,
  dataType,
  toRemove,
});

export const filterReset = () => ({
  type: FILTER_RESET,
});

export const configJsonLoaded = () => ({
  type: CONFIG_JSON_LOADED,
});

export const pollConfigUpdated = () => ({
  type: POLL_CONFIG_UPDATED,
});

export const pollConfigLoaded = (pollConfig) => ({
  type: POLL_CONFIG_LOADED,
  pollConfig,
});

export const validatePollCode = (poll_id, poll_code) => ({
  type: VALIDATE_POLL_CODE,
  poll_id,
  poll_code,
});

export const validatePoll = (poll_id, poll_code) => ({
  type: VALIDATE_POLL,
  poll_id,
  poll_code,
});

export const setPollId = (poll_id) => ({
  type: SET_POLL_ID,
  poll_id,
});

export const setPollCode = (poll_code) => ({
  type: SET_POLL_CODE,
  poll_code,
});

export const showPollDialog = (poll_id) => ({
  type: SHOW_POLL_DIALOG,
  poll_id,
});

export const hidePollDialog = () => ({
  type: HIDE_POLL_DIALOG,
});

export const goToNextPollStep = (question_id) => ({
  type: GO_TO_NEXT_POLL_STEP,
  question_id,
});

export const goToPreviousPollStep = (question_id) => ({
  type: GO_TO_PREVIOUS_POLL_STEP,
  question_id,
});

export const submitPoll = (poll_id) => ({
  type: SUBMIT_POLL,
  poll_id,
});

export const setPollStep = (poll_id, question_id, data) => ({
  type: SET_POLL_STEP,
  poll_id,
  question_id,
  data,
});

export const setPollError = (error) => ({
  type: SET_POLL_ERROR,
  error,
});

export const setPollPage = (page) => ({
  type: SET_POLL_PAGE,
  page,
});

export const setPollMark = (value) => ({
  type: SET_POLL_MARK,
  value,
});

export const setPollComment = (value) => ({
  type: SET_POLL_COMMENT,
  value,
});

export const setPollChoice = (value) => ({
  type: SET_POLL_CHOICE,
  value,
});

export const setPollMultiple = (value) => ({
  type: SET_POLL_MULTIPLE,
  value,
});

export const fetchContributionsFeed = (userAction) => ({
  type: FETCH_CONTRIBUTIONS_FEED,
  userAction,
});

export const contributionsFeedLoaded = (payload) => ({
  type: CONTRIBUTIONS_FEED_LOADED,
  payload,
});

export const setContributionsFeedError = (error) => ({
  type: SET_CONTRIBUTIONS_FEED_ERROR,
  error,
});

export const activateContributionsRefresh = (activate) => ({
  type: ACTIVATE_CONTRIBUTIONS_REFRESH,
  activate,
});

export const fetchSocialFeed = (payload) => ({
  type: FETCH_SOCIAL_FEED,
  payload,
});

export const socialFeedLoaded = (platform, posts, initialPayload) => ({
  type: SOCIAL_FEED_LOADED,
  platform,
  posts,
  initialPayload,
});

export const setSocialFeedError = (error) => ({
  type: SET_SOCIAL_FEED_ERROR,
  error,
});

export const showIntersticiel = (appIsBooting, next) => ({
  type: SHOW_INTERSTICIEL,
  appIsBooting,
  next,
});

export const hideIntersticiel = () => ({
  type: HIDE_INTERSTICIEL,
});

export const showIntersticielCloseButton = () => ({
  type: SHOW_INTERSTICIEL_CLOSE_BUTTON,
});

export const hideIntersticielCloseButton = () => ({
  type: HIDE_INTERSTICIEL_CLOSE_BUTTON,
});

export const showFullLoader = () => ({
  type: SHOW_FULL_LOADER,
});

export const hideFullLoader = () => ({
  type: HIDE_FULL_LOADER,
});

export const flightScheduleClicked = (id) => ({
  type: FLIGHT_CLICKED,
  data: id,
});

export const adClicked = (ad, url) => ({
  type: AD_CLICKED,
  ad,
  url,
});

export const linkClicked = (url) => ({
  type: LINK_CLICKED,
  url,
});

export const showNotification = (opts) => ({ type: SHOW_NOTIF, ...opts });

export const editNotification = (opts) => ({ type: EDIT_NOTIF, ...opts });

export const removeNotification = (uid) => ({ type: REMOVE_NOTIF, uid });

export const sendAppointmentRequest = (dataId, dataType, dataOriginalId) => ({
  type: SEND_APPOINTMENT_REQUEST,
  dataId,
  dataType,
  dataOriginalId,
});
export const appointmentRequestSent = (dataId, dataType) => ({
  type: APPOINTMENT_REQUEST_SENT,
  dataId,
  dataType,
});
export const appointmentRequestSendResult = (
  success,
  dataOriginalId,
  dataType,
  dataId,
  status
) => ({
  type: APPOINTMENT_REQUEST_SEND_RESULT,
  success,
  dataOriginalId,
  dataType,
  dataId,
  status,
});

export const contactRequestPerformed = ({ id, dataType, ws, error }) => ({
  type: CONTACT_REQUEST_PERFORMED,
  id,
  dataType,
  ws,
  error,
});

export const realTimeConnected = () => ({
  type: REAL_TIME_CONNECTED,
});
export const realTimeDisconnected = () => ({
  type: REAL_TIME_DISCONNECTED,
});

export const synopticAgendaTabIndexUpdate = (index) => ({
  type: SYNOPTIC_AGENDA_TAB_INDEX_UPDATE,
  index,
});

export const navigateToSynopticWithoutContext = () =>
getAgendaProps() ? navigate(SYNOPTIC_AGENDA_PAGE_KEY, getAgendaProps()) : showNotification({
  message: getLabels().common.error,
  level: NotificationLevels.ERROR,
});

export const adSwap = (adBundleAttributionKey, ad) => ({
  type: AD_SWAP,
  adBundleAttributionKey,
  ad,
});

export const updateKeyboardState = ({ isOpen, height }) => ({
  type: KEYBOARD_TOGGLED,
  isOpen,
  height,
});

export const taigaSearch = ({ fields, dataType, skipOngoingStatus = false }) => (dispatch) => {
  function next({ error, data }) {
    if (error === WS_ERRORS.AUTH) {
      setTimeout(() => {
        dispatch(navigate(LOGIN_PAGE_KEY, { nextRoute: { pageKey: SEARCH_TAIGA_PAGE_KEY } }));
      }, 2000);
    }
    dispatch(taigaSearchPerformed(error, data, dataType, fields));
  }

  switch (dataType) {
    case DATA_TYPE_PARTICIPANTS:
      if (!skipOngoingStatus) {
        dispatch(taigaSearchOngoing(dataType));
      }
      ParticipantsService.search(fields, next);
      break;

    default:
      console.error(`Taiga search not implemented yet for data type ${dataType}`);
  }
};

export const taigaSearchOngoing = (dataType) => ({
  type: TAIGA_SEARCH_ONGOING,
  dataType,
  status: STATUS.PENDING,
});

const taigaSearchPerformed = (error, data, dataType, fields) => ({
  type: TAIGA_SEARCH_PERFORMED,
  error,
  data,
  dataType,
  fields,
  status: STATUS.FETCHED,
});

export const taigaSearchClear = () => ({
  type: TAIGA_SEARCH_CLEARED,
});

// Related to web Page Visibility API
export const documentVisible = (value) => ({
  type: DOCUMENT_VISIBLE,
  value,
});

export const klipsoLeadsSetDisclaimerStatus = (value) => ({
  type: KLIPSOLEADS_SET_DISCLAIMER_STATUS,
  value,
});

export const klipsoLeadsRegisterSuccess = ({
  licence,
  userName,
  instance,
  exhibitorId,
  exhibitorName,
  eventDateBegin,
  eventDateEnd,
  eventDateEndAsLong,
  eventId,
  eventName,
  checkPointId,
  formFields,
}) => ({
  type: KLIPSOLEADS_REGISTER_SUCCESS,
  context: {
    exhibitor: {
      id: exhibitorId,
      name: exhibitorName || '',
    },
    event: {
      dateBegin: eventDateBegin,
      dateEnd: eventDateEnd,
      dateEndAsLong: eventDateEndAsLong,
      id: eventId,
      name: eventName || '',
    },
    instance,
    licence,
    userName,
    checkPointId,
  },
  formFields,
});

export const klipsoLeadsNavigate = (props) => navigate(KLIPSO_LEADS_PAGE_KEY, props);
// NB: Props
//  - searchEnabled
//  - formScreenEnabled
//  - extendedMenuScreenEnabled
// are set to false when no boolean value is provided (see reducer)

export const klipsoLeadsSetSortedByCompany = (value) => ({
  type: KLIPSOLEADS_SET_SORTED_BY_COMPANY,
  value,
});

export const klipsoLeadsEnableSearch = () => klipsoLeadsNavigate({ searchEnabled: true });

export const klipsoLeadsExitSearch = () => navigateBack();
// klipsoLeadsNavigate()

export function klipsoLeadsEnableFormScreen(badgeData) {
  const existingContact = getContact(badgeData.code);
  let newContact;

  if (!existingContact) {
    newContact = {
      ...badgeData,
    };
  }

  return klipsoLeadsNavigate({
    formScreenEnabled: true,
    currentContact: existingContact || newContact,
  });
}

export const klipsoLeadsExitFormScreen = () => navigateBack();
// klipsoLeadsNavigate()

export const klipsoLeadsContactsUpdated = (contacts) => ({
  type: KLIPSOLEADS_CONTACTS_UPDATED,
  contacts,
});

export const klipsoLeadsSetSyncOngoing = (value) => ({
  type: KLIPSOLEADS_SET_SYNC_ONGOING,
  value,
});

export const klipsoLeadsEnableExtendedMenuScreen = () =>
  klipsoLeadsNavigate({ extendedMenuScreenEnabled: true });
export const klipsoLeadsExitExtendedMenuScreen = () => navigateBack();
// klipsoLeadsNavigate()

export function klipsoLeadsResetLicence() {
  setContext(null);
  setFormFields(null);
  saveContacts([]);

  return {
    type: KLIPSOLEADS_RESET_LICENCE,
  };
}

export const badgeScanWithoutResult = () => ({
  type: BADGE_SCAN_WITHOUT_RESULT,
});

export const scanContact = () => (dispatch) => {
  if (isSessionValid()) {
    scanNewContact();
    dispatch({
      type: CONTACTS_SCAN_STARTED,
    });
  } else {
    dispatch({
      type: CONTACTS_SCAN_UNAUTHORISED,
    });
  }
};
export const contactsUpdated = (contacts) => ({
  type: CONTACTS_UPDATED,
  contacts,
});

/*
export function saveContact (contat) {
    Contacts.saveContact(contact);
    return {
        type: CONTACTS_SAVED,
    };
}
*/

export const codificationsFetched = (codifications) => ({
  type: CODIFICATIONS_FETCHED,
  codifications,
});

export const startLoginKlipsoSSO = () => ({
  type: PERFORM_LOGIN_KLIPSO_SSO,
});

export const setLoginSSOStatus = (loggedInSSO, sso, error) => ({
  type: SET_LOGIN_SSO_STATUS,
  loggedInSSO,
  sso,
  error,
});

export function fetchHappenings() {
  const happenings = Query.getAll(DATA_TYPE_HAPPENINGS);
  var campaign = null;
  // Get full data for each entry
  let status;
  if (Db.isDataReady() !== true) {
    status = STATUS.PENDING;
  } else {
    campaign = happenings && happenings[0] ? happenings[0] : null;
    status = STATUS.FETCHED;
  }
  return {
    type: FETCH_HAPPENINGS_FEED,
    happenings,
    campaign,
    status,
  };
}

const getServiceIdByLumpMainId = (main_id, dataType) => {
  const items = Query.getTopCategories(dataType);
  let parentId;
  items && items.map((item) => {
    if (item.lump.main_id === main_id) {
      parentId = item.id;
    }
  })
  return parentId;
}

export function fetchGmapPlaces() {
  let gmapplaces = {};
  let gmapplacesCategories = {};
  // Get full data for each entry
  let status;

  if (Db.isDataReady() !== true) {
    status = STATUS.PENDING;
  } else {
    gmapplaces = Query.getAll(DATA_TYPE_GMAP_PLACES);
    gmapplaces.map((gmapplace) => {
      gmapplace.position = gmapplace.gmap_position;
      delete gmapplace.gmap_position;
      gmapplace.icon = gmapplace.gmap_icon;
      delete gmapplace.gmap_icon;
    });
    gmapplacesCategories = Query.getAll(config.MAP.GOOGLE_MAP.USED_TABLE);
    gmapplacesCategories =
      gmapplacesCategories &&
      gmapplacesCategories.filter(function(gmapplacesCategory) {
        return gmapplacesCategory.parent_id && gmapplacesCategory.parent_id===getServiceIdByLumpMainId("_BY_PARCOURS_", config.MAP.GOOGLE_MAP.USED_TABLE);
      });

    status = STATUS.FETCHED;
  }
  return {
    type: FETCH_GMAP_PLACES,
    gmapplaces,
    gmapplacesCategories,
    status,
  };
}

export const showModalPrivacy = () => ({
  type: SHOW_MODAL_PRIVACY_AGREEMENT,
});

export const validateModalPrivacyAgreement = () => ({
  type: VALIDATE_MODAL_PRIVACY_AGREEMENT,
});
