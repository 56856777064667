import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import * as dialogsConfig from 'data/config/dialogsConfig';

//https://mui.com/api/dialog/
export default function DialogMigrate({
  children,
  disableBackdropClick,
  disableEscapeKeyDown,
  onClose,
  open,
  onBackdropClick,
  id,
  className,
  style,
  fullScreen,
}) {
  const isDisableBackdropClick = dialogsConfig.disableBackdropClick
    ? dialogsConfig.disableBackdropClick
    : disableBackdropClick
    ? disableBackdropClick
    : false;
  const isDisableEscapeKeyDown = dialogsConfig.disableEscapeKeyDown
    ? dialogsConfig.disableEscapeKeyDown
    : disableEscapeKeyDown
    ? disableEscapeKeyDown
    : false;
  const handleClose = (event, reason) => {
    if (isDisableBackdropClick && reason === 'backdropClick') {
      return false;
    }

    if (isDisableEscapeKeyDown && reason === 'escapeKeyDown') {
      return false;
    }

    if (typeof onClose === 'function') {
      onClose();
    }
  };

  return (
    <Dialog
      id={id}
      className={className}
      fullScreen={fullScreen}
      style={style}
      open={open}
      onBackdropClick={onBackdropClick}
      onClose={handleClose}
      PaperProps={{
        style: {
          border: '2px solid  #00D072 ',
          borderBottomRightRadius: '50px   ',
        },
      }}
    >
      {children}
    </Dialog>
  );
}
